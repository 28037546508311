<h1>
    <button mat-button routerLink="/manage/projects" onclick="history.back()">
        <mat-icon>keyboard_backspace</mat-icon>
    </button>
    {{ (project | async)?.name }}
</h1>

<div fxLayout="column" class="cs-table">
    <div fxLayout="row" class="cs-table-row enclosing">
        <div fxFlex="40">Role</div>
        <div fxFlex="30">Compensation</div>
        <div fxFlex="0 0 80px" fxFlexOffset="auto"></div>
    </div>


    <div
        fxLayout="row"
        *ngFor="let combo of combinedData$ | async"
        class="cs-table-row highlight"
        fxLayoutAlign="start center"
    >

        <div fxFlex="40">
            {{ combo.role.name }}
        </div>

        <div fxFlex="30">
            {{ combo.compensation + ' €' }}
        </div>

        <div fxFlex="0 0 100px" fxFlexOffset="auto">
            <button
                mat-icon-button
                (click)="openEditCompensationModal(combo.role, combo.compensation)"
                matTooltip="Edit Compensation"
            >
                <mat-icon>edit</mat-icon>
            </button>
            <button
                mat-icon-button
                (click)="removeFromProject(combo.role.id)"
                matTooltip="Remove Role"
            >
                <mat-icon color="warn">delete</mat-icon>
            </button>
        </div>
    </div>
    </div>


<div fxLayout="row" fxLayoutAlign="end">
    <button mat-stroked-button (click)="openEditCompensationModal(null, null)">
        Add Role
    </button>
</div>

