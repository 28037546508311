import * as moment from "moment";
import { Moment } from "moment";

export interface TimesheetEntryDTO {
    id: number;
    userId: string;
    userDisplayName: string;
    customerId: number;
    customerShortName: string;
    projectId: number;
    projectName: string;
    roleId: number;
    roleName: string;
    year: number;
    month: number;
    day: number;

    description: string;
    duration: number;
    startTime: any;
    endTime: any;
}

export interface TimesheetEntryCreateDTO {
    projectId: number;
    roleId: number;
    year: number;
    month: number;
    day: number;

    duration: number;
    description: string;
}

export class TimesheetEntryTableItem {
    constructor(timesheetEntry: TimesheetEntryDTO) {
        this.id = timesheetEntry.id;
        this.date = moment()
            .year(timesheetEntry.year)
            .month(timesheetEntry.month - 1)
            .date(timesheetEntry.day)
            .startOf("day");
        this.projectName = timesheetEntry.projectName;
        this.projectId = timesheetEntry.projectId;
        this.roleId = timesheetEntry.roleId;
        this.roleName = timesheetEntry.roleName;
        this.customerShortName = timesheetEntry.customerShortName;
        this.description = timesheetEntry.description;
        this.duration = timesheetEntry.duration;
    }

    public id: number;
    public date: Moment;
    public projectId: number;
    public roleId: number;
    public roleName: string;
    public projectName: string;
    public customerShortName: string;
    public description: string;
    public duration: number;
}
