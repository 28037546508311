<form #roleForm="ngForm" (ngSubmit)="saveRole()">
    <h2 mat-dialog-title>
        {{ dialogModel.id ? "Edit" : "Create" }} Project Role
    </h2>
    <mat-dialog-content>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <mat-form-field fxFlex="1 1 48%">
                <mat-label>Role Name</mat-label>
                <input
                    matInput
                    [(ngModel)]="dialogModel.name"
                    id="role-name"
                    name="role-name"
                    type="text"
                    maxlength="64"
                    required
                />
            </mat-form-field>

            <mat-form-field fxFlex="1 1 48%">
                <mat-label>Invoice Reference</mat-label>
                <input
                    matInput
                    [(ngModel)]="dialogModel.invoiceReference"
                    id="role-invoceReference"
                    name="role-invoceReference"
                    type="text"
                    maxlength="32"
                    required
                />
            </mat-form-field>
        </div>

        <mat-form-field style="width: 100%">
            <mat-label>Description</mat-label>
            <input
                matInput
                [(ngModel)]="dialogModel.description"
                id="role-description"
                name="role-description"
                type="text"
                maxlength="255"
                required
            />
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button
            mat-stroked-button
            type="button"
            *ngIf="dialogModel.id"
            color="warn"
            (click)="deleteRole()"
        >
            Delete
        </button>
        <button
            mat-stroked-button
            style="margin-left: 10px"
            type="submit"
            [disabled]="!roleForm.valid"
        >
            Save
        </button>
        <button
            mat-stroked-button
            style="margin-left: 10px"
            type="button"
            [mat-dialog-close]="false"
        >
            Cancel
        </button>
    </mat-dialog-actions>
</form>
