<h1>Holidays</h1>

<button id="yearMinus" mat-icon-button (click)="year = year - 1; reloadHolidays()">
    <mat-icon>keyboard_arrow_left</mat-icon>
</button>
<mat-form-field>
    <mat-label>Year</mat-label>
    <input matInput type="number" [(ngModel)]="year" id="year" name="year" (change)="reloadHolidays()" />
</mat-form-field>
<button id="yearPlus" mat-icon-button (click)="year = year + 1; reloadHolidays()">
    <mat-icon>keyboard_arrow_right</mat-icon>
</button>

<mat-form-field>
    <mat-label>Region</mat-label>
    <mat-select
        id="region"
        name="region"
        [(ngModel)]="region"
        (ngModelChange)="reloadHolidays()"
    >
        <mat-option *ngFor="let region of availableRegions" [value]="region">{{
                region.name
            }}</mat-option>
    </mat-select>
</mat-form-field>

<div class="cs-table">
    <div class="cs-table-row enclosing">
        <div fxFlex>ID</div>
        <div fxFlex>Name</div>
        <div fxFlex>Date</div>
        <div fxFlex>Type</div>
    </div>

    <div
        class="cs-table-row action"
        matRipple
        *ngFor="let holiday of filteredHolidays"
        (click)="openHolidayModal(holiday)"
    >
        <div fxFlex>{{ holiday.id }}</div>
        <div fxFlex>{{ holiday.name }}</div>
        <div fxFlex>{{ holiday.date.format("DD.MM.YYYY") }}</div>
        <div fxFlex>{{ HolidayType[holiday.type] }}</div>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="end">
    <button mat-stroked-button color="warn" (click)="deleteAllVisibleHolidays()" style="margin-right: 1em" [disabled]="this.filteredHolidays == null || this.filteredHolidays.length === 0">
        Delete all visible
    </button>
    <button mat-stroked-button color="accent" (click)="importPublicHolidays()" style="margin-right: 1em" [disabled]="this.region?.code == null || this.region?.code == ''"
            [attr.title]="this.region?.code == null || this.region?.code == '' ? 'Region does not have needed code for auto-importing public holidays' : null">
        Import public holidays
    </button>
    <button mat-stroked-button color="primary" (click)="openHolidayModal(newHolidayWithRegionOnly(this.region))">
        Create holiday
    </button>
</div>
