import {Component, Inject, OnInit} from '@angular/core';
import {Mode} from '../../mode';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {RegionService} from '../../regions/region.service';
import {Holiday, HolidaySaveModel, HolidayType} from '../holiday';
import {HolidayService} from '../holiday.service';
import {Region} from '../../regions/region';
import {EnumHelper} from '../../common/EnumHelper';
import {Moment} from 'moment';
import * as moment from 'moment';

@Component({
    selector: 'app-create-holiday-dialog',
    templateUrl: './create-holiday-dialog.component.html',
    styleUrls: ['./create-holiday-dialog.component.sass'],
})
export class CreateHolidayDialogComponent implements OnInit {
    public availableRegions: Region[];
    public holidayTypes: (typeof HolidayType)[];

    public model: HolidaySaveModel;
    public mode: Mode;
    public datePickerModel: Moment;

    public Mode = Mode; // For template


    constructor(private holidayService: HolidayService, private regionService: RegionService, @Inject(MAT_DIALOG_DATA) public holiday: Holiday, private dialogRef: MatDialogRef<Holiday>) {
        if (holiday) {
            this.model = HolidaySaveModel.fromHoliday(holiday);
            this.mode = Mode.EDIT;
        } else {
            this.model = new HolidaySaveModel();
            this.mode = Mode.CREATE;
        }
    }

    ngOnInit() {
        this.regionService.readRegions().subscribe((next) => (this.availableRegions = next));
        this.holidayTypes = EnumHelper.values(HolidayType);
        this.datePickerModel = this.model.date ? moment(this.model.date) : null;
    }

    datePickerChanged() {
        this.model.date = this.datePickerModel.format('YYYY-MM-DD');
    }

    async saveHoliday() {
        await this.holidayService.saveHoliday(this.model);
        this.dialogRef.close(true);
    }


    deleteHoliday() {
        this.holidayService
            .deleteHoliday(this.model)
            .subscribe({ complete: () => this.dialogRef.close(true) });
    }

    protected readonly HolidayType = HolidayType;
}
