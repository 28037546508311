import {Moment} from 'moment';
import {Region} from '../regions/region';
import * as moment from 'moment/moment';
import {ApiFeiertag} from './api-feiertag';

export class Holiday {
    id: number;
    name: string;
    date: Moment;
    type: HolidayType;
    region: Region;


    constructor(id: number, name: string, date: moment.Moment, type: HolidayType, region: Region) {
        this.id = id;
        this.name = name;
        this.date = date;
        this.type = type;
        this.region = region;
    }
}

export class HolidayDto {
    id: number;
    name: string;
    date: number[];
    type: string;
    region: Region;


    constructor(id: number, name: string, date: number[], type: string, region: Region) {
        this.id = id;
        this.name = name;
        this.date = date;
        this.type = type;
        this.region = region;
    }

    static toHoliday(holidayDto: HolidayDto): Holiday {
        return new Holiday(
            holidayDto.id,
            holidayDto.name,
            moment([holidayDto.date[0], holidayDto.date[1] - 1, holidayDto.date[2]]),
            HolidayType[holidayDto.type],
            new Region(holidayDto.region.id, holidayDto.region.name, holidayDto.region.code)
        )
    }
}

export class HolidaySaveModel {
    id: number;
    name: string;
    date: string;
    type: string;
    regionId: number;

    static fromHoliday(holiday: Holiday): HolidaySaveModel {
        return {
            id: holiday.id,
            name: holiday.name,
            date: holiday.date?.format('YYYY-MM-DD'),
            type: HolidayType[holiday.type],
            regionId: holiday.region.id
        };
    }

    static fromApiFeiertagAndRegion(apiFeiertag: ApiFeiertag, regionId: number): HolidaySaveModel {
        return {
            id: undefined,
            name: apiFeiertag.fname,
            date: apiFeiertag.date,
            type: HolidayType[HolidayType.PUBLIC_HOLIDAY],
            regionId: regionId
        };
    }
}

export enum HolidayType {
    PUBLIC_HOLIDAY,
    COMPANY_HOLIDAY
}
