import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RegionService} from './region.service';
import {RegionOverviewComponent} from './region-overview/region-overview.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {CreateRegionDialogComponent} from './create-region-dialog/create-region-dialog.component';
import {FormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        FormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule
    ],
    declarations: [RegionOverviewComponent, CreateRegionDialogComponent],
    exports: [],
    providers: [RegionService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RegionsModule {}
