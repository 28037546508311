import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UsersModule } from "../users/users.module";
import { MyProjectsOverviewComponent } from "./project-overview/project-overview.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CreateProjectDialogComponent } from "./project-admin-overview/create-project-dialog/create-project-dialog.component";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { ProjectAdminOverviewComponent } from "./project-admin-overview/project-admin-overview.component";
import { ProjectAdminDeleteConfirmDialogComponent } from "./project-admin-overview/project-admin-delete-confirm-dialog/project-admin-delete-confirm-dialog.component";
import { ProjectRolesOverviewComponent } from "./project-roles-overview/project-roles-overview.component";
import { ProjectRoleService } from "./project-role.service";
import { EditDialogComponent } from "./project-roles-overview/edit-dialog/edit-dialog.component";
import { ProjectMemberOverviewComponent } from "./project-admin-overview/project-member-overview/project-member-overview.component";
import { EditMemberDialogComponent } from "./project-admin-overview/project-member-overview/edit-member-dialog/edit-member-dialog.component";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatBadgeModule } from "@angular/material/badge";
import { MatTableModule } from "@angular/material/table";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCardModule } from "@angular/material/card";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatChipsModule } from "@angular/material/chips";
import { MatRippleModule } from "@angular/material/core";
import { ProjectCompensationOverviewComponent } from './project-admin-overview/project-compensation-overview/project-compensation-overview.component';
import { EditCompensationDialogComponent } from './project-admin-overview/project-compensation-overview/edit-compensation-dialog/edit-compensation-dialog.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        UsersModule,
        MatInputModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatSelectModule,
        MatButtonModule,
        MatTableModule,
        MatIconModule,
        MatTooltipModule,
        FlexLayoutModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatPaginatorModule,
        MatExpansionModule,
        MatCardModule,
        MatRippleModule,
    ],
    declarations: [
        MyProjectsOverviewComponent,
        ProjectAdminOverviewComponent,
        CreateProjectDialogComponent,
        ProjectAdminDeleteConfirmDialogComponent,
        ProjectRolesOverviewComponent,
        EditDialogComponent,
        ProjectMemberOverviewComponent,
        EditMemberDialogComponent,
        ProjectCompensationOverviewComponent,
        EditCompensationDialogComponent,
    ],
    exports: [],
    entryComponents: [
        CreateProjectDialogComponent,
        ProjectAdminDeleteConfirmDialogComponent,
        EditDialogComponent,
        EditMemberDialogComponent,
    ],
    providers: [ProjectRoleService],
})
export class ProjectsModule {}
