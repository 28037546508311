<h1>My Projects</h1>

<div fxLayout="column" class="cs-table">
    <div fxLayout="row" class="cs-table-row enclosing">
        <div fxFlex>Project Name</div>
        <div fxFlex>Customer</div>
        <div fxFlex>Time span</div>
        <div fxFlex="0 1 160px" class="cgi-cell-right">
            Default | Favorite | Hide
        </div>
    </div>

    <div
        fxLayout="row"
        *ngFor="let project of projects"
        class="cs-table-row highlight"
        [ngClass]="!project.running ? 'project-ended' : ''"
        fxLayoutAlign="start center"
    >
        <div fxFlex>
            <span>{{ project.name }}</span>
        </div>
        <div fxFlex>{{ project.customerName }}</div>
        <div fxFlex>
            {{ project.start.format("DD.MM.YYYY") }} -
            {{ project.end.format("DD.MM.YYYY") }}
        </div>
        <div fxFlex="0 1 160px" class="cgi-cell-right">
            <button
                mat-icon-button
                [color]="project.default ? 'warn' : ''"
                (click)="toggleDefault(project)"
                [disabled]="project.hidden"
                matTooltip="Default"
            >
                <mat-icon>done</mat-icon>
            </button>
            <button
                mat-icon-button
                [color]="project.favorite ? 'warn' : ''"
                (click)="toggleFavorite(project)"
                [disabled]="project.hidden"
                matTooltip="Favorite"
            >
                <mat-icon>favorite_border</mat-icon>
            </button>
            <button
                mat-icon-button
                [color]="project.hidden ? 'warn' : ''"
                (click)="toggleHidden(project)"
                [disabled]="project.default || project.favorite"
                matTooltip="Hide"
            >
                <mat-icon>visibility_off</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="row" class="cgi-table-row footer"></div>
</div>
