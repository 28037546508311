import { Component, Inject, OnInit } from "@angular/core";
import { ProjectRoleDTO } from "../../project-role";
import { ProjectRoleService } from "../../project-role.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-edit-dialog",
    templateUrl: "./edit-dialog.component.html",
    styleUrls: ["./edit-dialog.component.sass"],
})
export class EditDialogComponent implements OnInit {
    public dialogModel: RoleDialogModel;

    constructor(
        private projectRoleService: ProjectRoleService,
        private dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) private data: ProjectRoleDTO | null
    ) {
        if (data) {
            this.dialogModel = {
                id: this.data.id,
                name: this.data.name,
                invoiceReference: this.data.invoiceReference,
                description: this.data.description,
            };
        } else {
            this.dialogModel = {
                description: "",
                invoiceReference: "",
                name: "",
            };
        }
    }

    ngOnInit() {}

    saveRole() {
        const projectRole = {
            name: this.dialogModel.name,
            invoiceReference: this.dialogModel.invoiceReference,
            description: this.dialogModel.description,
        };

        if (this.dialogModel.id) {
            this.projectRoleService
                .update(this.dialogModel.id, projectRole)
                .subscribe(() => this.dialogRef.close(true));
        } else {
            this.projectRoleService
                .create(projectRole)
                .subscribe(() => this.dialogRef.close(true));
        }
    }

    deleteRole() {
        this.projectRoleService
            .delete(this.dialogModel.id)
            .subscribe(() => this.dialogRef.close(true));
    }
}

interface RoleDialogModel {
    id?: number;
    name: string;
    description: string;
    invoiceReference: string;
}
