import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ProjectRoleDTO, ProjectRoleSaveDTO, ProjectRoleCompensationDTO } from "./project-role";
import { Observable } from "rxjs";

@Injectable()
export class ProjectRoleService {
    constructor(private http: HttpClient) {}

    readAll(): Observable<ProjectRoleDTO[]> {
        return this.http.get<ProjectRoleDTO[]>(
            environment.backendUrl + "/api/projectroles"
        );
    }

    readAllAssignedProjectRoles(
        projectIds: number[]
    ): Observable<ProjectRoleDTO[]> {
        const params = {
            projectIds: projectIds.map((projectId) => projectId.toString()),
        };
        return this.http.get<ProjectRoleDTO[]>(
            environment.backendUrl + "/api/my/projectrole/assigned",
            { params: params }
        );
    }

    create(projectRole: ProjectRoleSaveDTO): Observable<any> {
        return this.http.post(
            environment.backendUrl + "/api/projectroles",
            projectRole,
            { responseType: "text" }
        );
    }

    update(id: number, projectRole: ProjectRoleSaveDTO): Observable<any> {
        return this.http.post(
            environment.backendUrl + `/api/projectroles/${id}`,
            projectRole,
            { responseType: "text" }
        );
    }

    delete(id: number): Observable<any> {
        return this.http.delete(
            environment.backendUrl + `/api/projectroles/${id}`,
            { responseType: "text" }
        );
    }

    getCompensationsByProject(projectId: number): Observable<ProjectRoleCompensationDTO[]> {
        return this.http.get<ProjectRoleCompensationDTO[]>(
            environment.backendUrl + "/api/projectrole/compensations/" + projectId
        );
    }

    getCompensation(roleId: number, projectId: number): Observable<number> {
        return this.http.get<number>(
            environment.backendUrl + "/api/projectrole/compensation/" + projectId + "/" + roleId
        );
    }

    saveProjectRoleCompensation(roleId: number, projectId: number, compensation: number): Observable<any> {
        const params = {
                    compensation: compensation,
                };

        return this.http.post(
                environment.backendUrl + `/api/projectrole/compensation/${projectId}/${roleId}`,
                null,
                { params }
            );
        }

    getCompensatedRolesForProject(projectId: number): Observable<ProjectRoleDTO[]>{
        return this.http.get<ProjectRoleDTO[]>(
            environment.backendUrl + "/api/projectrole/assigned/" + projectId
        );
    }

    removeRoleFromProject(roleId: number, projectId: number): Observable<any>{
        return this.http.delete(
            environment.backendUrl + `/api/projectroles/${projectId}/${roleId}`
        );
    }


}
