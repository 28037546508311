<h1>Reporting</h1>
<mat-vertical-stepper [linear]="true" #stepper>
    <mat-step>
        <ng-template matStepLabel>General reporting options</ng-template>
        <mat-form-field style="padding-right: 10px">
            <mat-label>Start date</mat-label>
            <input
                matInput
                [max]="pdfExportModel.end"
                [matDatepicker]="pdfStartPicker"
                [(ngModel)]="pdfExportModel.start"
                name="startDate"
                id="pdfStartDate"
                required
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="pdfStartPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #pdfStartPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
            <mat-label>End date</mat-label>
            <input
                matInput
                [min]="pdfExportModel.start"
                [matDatepicker]="pdfEndPicker"
                [(ngModel)]="pdfExportModel.end"
                name="endDate"
                id="pdfEndDate"
                required
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="pdfEndPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #pdfEndPicker></mat-datepicker>
        </mat-form-field>
        <br/>
        <div fxLayout="row">
            <button
                fxFlex
                mat-stroked-button
                style="margin-right: 10px"
                (click)="setStartAndEndDateLastMonth(pdfExportModel)"
            >
                last month
            </button>
            <button
                fxFlex
                mat-stroked-button
                style="margin-right: 10px"
                (click)="setStartAndEndDateLastQuater(pdfExportModel)"
            >
                last quarter
            </button>
            <button
                fxFlex
                mat-stroked-button
                style="margin-right: 10px"
                (click)="setStartAndEndDateLastHalfYear(pdfExportModel)"
            >
                last half-year
            </button>
            <button
                fxFlex
                mat-stroked-button
                style="margin-right: 10px"
                (click)="setStartAndEndDateLastYear(pdfExportModel)"
            >
                last year
            </button>
        </div>
        <br/>
        <div fxLayout="row">
            <button
                fxFlex
                mat-stroked-button
                style="margin-right: 10px"
                (click)="setStartAndEndDateCurrentMonth(pdfExportModel)"
            >
                current month
            </button>
            <button
                fxFlex
                mat-stroked-button
                style="margin-right: 10px"
                (click)="setStartAndEndDateCurrentQuarter(pdfExportModel)"
            >
                current quarter
            </button>
            <button
                fxFlex
                mat-stroked-button
                style="margin-right: 10px"
                (click)="setStartAndEndDateCurrentHalfYear(pdfExportModel)"
            >
                current half-year
            </button>
            <button
                fxFlex
                mat-stroked-button
                style="margin-right: 10px"
                (click)="setStartAndEndDateCurrentYear(pdfExportModel)"
            >
                current year
            </button>
        </div>
        <br/>

        <mat-form-field style="width: 180px">
            <mat-label>Locale</mat-label>
            <mat-select
                [(ngModel)]="pdfExportModel.locale"
                id="locale"
                name="locale"
            >
                <mat-option class="left circle" value="de-DE"
                >German (Germany)
                </mat-option
                >
                <mat-option class="left circle" value="en-GB"
                >English (UK)
                </mat-option
                >
                <mat-option class="left circle" value="en-US"
                >English (US)
                </mat-option
                >
            </mat-select>
        </mat-form-field>
        <br/>
        <mat-checkbox
            [(ngModel)]="pdfExportModel.ownSig"
            name="requireOwnSignature"
            id="requireOwnSignature"
        >
            Require our signature
        </mat-checkbox>
        <br/>
        <mat-checkbox
            [(ngModel)]="pdfExportModel.custSig"
            name="requireCusomerSignature"
            id="requireCustomerSignature"
        >
            Require customer's signature
        </mat-checkbox>
    </mat-step>

    <mat-step>
        <ng-template matStepLabel>Grouped by</ng-template>
        <mat-tab-group>
            <mat-tab label="User">
                <br/>
                <p>
                    Generates all Timesheets for the selected user, grouped by
                    Customer and Project
                </p>
                <br/>
                <form #userForm="ngForm">
                    <mat-form-field>
                        <mat-label>Select User</mat-label>
                        <mat-select
                            required="true"
                            id="user"
                            name="user"
                            [(ngModel)]="pdfExportModel.user"
                        >
                            <mat-option
                                *ngFor="let user of availableUsers"
                                class="left circle"
                                [value]="user"
                            >
                                {{ user.displayName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button
                        mat-stroked-button
                        style="margin-left: 20px"
                        (click)="downloadByUser()"
                        [disabled]="!userForm.valid"
                    >
                        Download
                    </button>
                </form>
            </mat-tab>
            <mat-tab label="Customer">
                <br/>
                <p>
                    Generates all Timesheets for the selected Customer, grouped
                    by Project Member and Project.
                </p>
                <br/>
                <form #customerForm="ngForm">
                    <mat-dialog-content>
                        <mat-form-field>
                            <mat-label>Select Customer</mat-label>
                            <mat-select
                                required="true"
                                id="customer"
                                name="customer"
                                [(ngModel)]="pdfExportModel.customer"
                            >
                                <mat-option
                                    *ngFor="let customer of availableCustomers"
                                    class="left circle"
                                    [value]="customer"
                                >
                                    {{ customer.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <button
                            mat-stroked-button
                            style="margin-left: 20px"
                            (click)="downloadByCustomer()"
                            [disabled]="!customerForm.valid"
                        >
                            Download
                        </button>
                    </mat-dialog-content>
                </form>
            </mat-tab>
        </mat-tab-group>
    </mat-step>
</mat-vertical-stepper>

<br/>
<br/>
<br/>

<h1>Excel-Timesheets</h1>
<mat-card appearance="outlined" style="padding: 20px">
    <mat-card-subtitle
    >Generates (Excel-)Timesheets for the selected customers and time
        period.
    </mat-card-subtitle
    >
    <form #customersForm="ngForm" fxLayout="row wrap">
        <mat-dialog-content fxFlex>
            <mat-form-field style="width: 300px; padding-right: 10px">
                <mat-label>Select Customers</mat-label>
                <mat-select
                    [multiple]="true"
                    required="true"
                    id="customers"
                    name="customers"
                    [(ngModel)]="excelExportModel.customers"
                >
                    <mat-option
                        *ngFor="let customer of availableCustomers"
                        class="left circle"
                        [value]="customer"
                    >
                        {{ customer.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-dialog-content>
        <mat-form-field style="padding-right: 10px" fxFlex>
            <mat-label>Start date</mat-label>
            <input
                matInput
                [max]="excelExportModel.end"
                [matDatepicker]="startPicker"
                [(ngModel)]="excelExportModel.start"
                name="startDate"
                id="startDate"
                required
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="startPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field fxFlex>
            <mat-label>End date</mat-label>
            <input
                matInput
                [min]="excelExportModel.start"
                [matDatepicker]="endPicker"
                [(ngModel)]="excelExportModel.end"
                name="endDate"
                id="endDate"
                required
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="endPicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
        <button fxFlex
            mat-stroked-button
            style="margin-left: 20px"
            (click)="downloadExcelExport()"
            [disabled]="!customersForm.valid"
        >
            Download
        </button>
    </form>
    <br/>
    <div fxLayout="row">
        <button
            fxFlex
            mat-stroked-button
            style="margin-right: 10px"
            (click)="setStartAndEndDateLastMonth(excelExportModel)"
        >
            last month
        </button>
        <button
            fxFlex
            mat-stroked-button
            style="margin-right: 10px"
            (click)="setStartAndEndDateLastQuater(excelExportModel)"
        >
            last quarter
        </button>
        <button
            fxFlex
            mat-stroked-button
            style="margin-right: 10px"
            (click)="setStartAndEndDateLastHalfYear(excelExportModel)"
        >
            last half-year
        </button>
        <button
            fxFlex
            mat-stroked-button
            style="margin-right: 10px"
            (click)="setStartAndEndDateLastYear(excelExportModel)"
        >
            last year
        </button>
    </div>
    <br/>
    <div fxLayout="row">
        <button
            fxFlex
            mat-stroked-button
            style="margin-right: 10px"
            (click)="setStartAndEndDateCurrentMonth(excelExportModel)"
        >
            current month
        </button>
        <button
            fxFlex
            mat-stroked-button
            style="margin-right: 10px"
            (click)="setStartAndEndDateCurrentQuarter(excelExportModel)"
        >
            current quarter
        </button>
        <button
            fxFlex
            mat-stroked-button
            style="margin-right: 10px"
            (click)="setStartAndEndDateCurrentHalfYear(excelExportModel)"
        >
            current half-year
        </button>
        <button
            fxFlex
            mat-stroked-button
            style="margin-right: 10px"
            (click)="setStartAndEndDateCurrentYear(excelExportModel)"
        >
            current year
        </button>
    </div>
</mat-card>
