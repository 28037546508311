import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {UserDeleteModel, UserDetails, UserSaveModel} from './userDetails';
import { Observable, ReplaySubject } from "rxjs";
import { Role } from "./role";
import { environment } from "../../environments/environment";

@Injectable()
export class UserService {
    public me: ReplaySubject<UserDetails> = new ReplaySubject<UserDetails>(1);

    constructor(private http: HttpClient) {}

    saveUser(user: UserSaveModel) {
        this.http.post(environment.backendUrl + "/api/user", user).subscribe();
    }

    readUsers(): Observable<UserDetails[]> {
        return this.http.get<UserDetails[]>(
            environment.backendUrl + "/api/user"
        );
    }

    readActiveUsers(): Observable<UserDetails[]> {
        return this.readUsers(); // TODO: Nur aktive User, ggf. ohne Rollen
    }

    public readMe(): Observable<UserDetails> {
        this.http
            .get<UserDetails>(environment.backendUrl + "/api/user/me")
            .subscribe((user) => {
                this.me.next(user);
            });
        return this.me;
    }

    public clearMe(): void {
        this.me.next(null);
    }

    readRoles(): Observable<Role[]> {
        return this.http.get<Role[]>(
            environment.backendUrl + "/api/user/roles"
        );
    }

    deleteUser(user: UserDeleteModel): Observable<any> {
        return this.http.delete<Observable<any>>(
            environment.backendUrl + "/api/user/" + user.principalId
        );
    }

     hardDeleteUser(user: UserDeleteModel){
        return this.http.delete<Observable<any>>(environment.backendUrl + '/api/user/delete/' + user.principalId);
     }
}
