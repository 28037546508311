import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProjectService } from "../../project.service";
import { Project, ProjectMemberDTO } from "../../project";
import { MatDialog } from "@angular/material/dialog";
import { UserService } from "../../../users/user.service";
import { ProjectRoleDTO } from "../../project-role";
import { ProjectRoleService } from "../../project-role.service";
import { Observable } from "rxjs";
import {
    EditMemberDialogComponent,
    EditMemberDialogModel,
} from "./edit-member-dialog/edit-member-dialog.component";

@Component({
    selector: "app-project-member-overview",
    templateUrl: "./project-member-overview.component.html",
    styleUrls: ["./project-member-overview.component.sass"],
})
export class ProjectMemberOverviewComponent implements OnInit {
    private projectId: number;
    public project: Observable<Project> = Observable.create();
    public projectRoles: Observable<ProjectRoleDTO[]> = Observable.create();
    public projectMemberDetails: Observable<ProjectMemberDTO[]>;

    constructor(
        private route: ActivatedRoute,
        private projectService: ProjectService,
        private projectRoleService: ProjectRoleService,
        private userService: UserService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe((data) => {
            this.projectId = +data.get("projectId");
            this.loadProject(this.projectId);
        });

        this.projectRoles = this.projectRoleService.readAll();
    }

    private loadProject(projectId: number | null) {
        if (projectId) {
            this.project = this.projectService.readProject(projectId);
            this.loadMembers(projectId);
        }
    }

    private loadMembers(projectId: number) {
        this.projectMemberDetails =
            this.projectService.readProjectMembers(projectId);
    }

    openEditMemberModal(param: ProjectMemberDTO) {
        const dialogReference = this.dialog.open(EditMemberDialogComponent, {
            data: {projectMember: param, projectId: this.projectId},
            width: "400px",
        });
        dialogReference.afterClosed().subscribe((result) => {
            if (result) {
                this.saveProjectMember(result, param != null);
            }
        });
    }

    private saveProjectMember(result: EditMemberDialogModel, wasEdit: boolean) {
        if (!wasEdit) {
            this.projectService
                .addProjectMember(this.projectId, result.principalId)
                .subscribe({
                    next: () =>
                        this.saveMemberRoles(result.principalId, result.roles),
                });
        } else {
            this.saveMemberRoles(result.principalId, result.roles);
        }
    }

    private saveMemberRoles(principalId: string, roles: number[]) {
        this.projectService
            .saveRolesForProjectMember(this.projectId, principalId, roles)
            .subscribe({
                next: () => this.loadMembers(this.projectId),
            });
    }

    removeFromProject(principalId: string) {
        this.projectService
            .removeProjectMember(this.projectId, principalId)
            .subscribe(() => this.loadMembers(this.projectId));
    }
}
