<h1>Projects</h1>

<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title> Filter options </mat-panel-title>
        </mat-expansion-panel-header>

        <div
            fxLayout="row wrap"
            fxLayoutAlign="start center"
            fxLayoutGap="40px"
            style="padding: 20px"
        >
            <mat-form-field>
                <mat-label>Customer</mat-label>
                <mat-select
                    id="customerSelect"
                    name="customerSelect"
                    [(ngModel)]="customerId"
                    (ngModelChange)="customerFilterChanged()"
                >
                    <mat-option>Any</mat-option>
                    <mat-option
                        *ngFor="let customer of customers | async"
                        [value]="customer.id"
                        >{{ customer.name }}</mat-option
                    >
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Project state</mat-label>
                <mat-select
                    id="stateSelect"
                    name="stateSelect"
                    [(ngModel)]="states"
                    (ngModelChange)="stateFilterChanged()"
                    multiple="true"
                >
                    <mat-option [value]="'PLANNED'">Planned</mat-option>
                    <mat-option [value]="'RUNNING'">Running</mat-option>
                    <mat-option [value]="'ENDED'">Ended</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-expansion-panel>
</mat-accordion>

<br />

<div fxLayout="column" class="cs-table">
    <div fxLayout="row" class="cs-table-row enclosing">
        <div fxFlex>Project Name</div>
        <div fxFlex>Customer</div>
        <div fxFlex="1 1 20%">Time span</div>
        <div fxFlex="0 0 100px" class="cgi-cell-right"></div>
    </div>

    <div
        fxLayout="row"
        *ngFor="let project of projects | async"
        class="cs-table-row highlight"
        fxLayoutAlign="start center"
        [ngClass]="
            project.hasEnded()
                ? 'disabled'
                : project.daysUntilEnd() < 21
                ? 'warning'
                : ''
        "
    >
        <div fxFlex>{{ project.name }}</div>
        <div fxFlex>{{ project.customerName }}</div>
        <div fxFlex="1 1 20%">
            {{ project.start.format("DD.MM.YYYY") }} -
            {{ project.end.format("DD.MM.YYYY") }}
        </div>
        <div fxFlex="0 0 150px" class="cs-cell-right">
            <button
                mat-icon-button
                (click)="openCreateProjectModal(project)"
                matTooltip="Edit Project Info"
            >
                <mat-icon>edit</mat-icon>
            </button>
            <button
                mat-icon-button
                (click)="openProjectMemberManagement(project)"
                matTooltip="Edit Project Members"
            >
                <mat-icon>people</mat-icon>
            </button>
            <button
                mat-icon-button
                (click)="openRoleCompensationModal(project)"
                matTooltip="Edit Roles & Compensation"
            >
                <mat-icon>euro_symbol</mat-icon>
            </button>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end" class="cs-table-row enclosing">
        <mat-paginator
            [length]="totalProjects"
            [pageSizeOptions]="[1, 10, 20, 50, 100]"
            [pageSize]="pageSize"
            (page)="pageChanged($event)"
            [pageIndex]="pageIndex"
        ></mat-paginator>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="end"></div>

<div fxLayout="row" fxLayoutAlign="space-between flex-start">
    <mat-card appearance="outlined">
        <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <div
                    style="
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        background-color: white;
                    "
                ></div>
                <div>planned or running</div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <div
                    style="
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        background-color: darkorange;
                    "
                ></div>
                <div>ending soon</div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <div
                    style="
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        background-color: slategray;
                    "
                ></div>
                <div>ended</div>
            </div>
        </mat-card-content>
    </mat-card>

    <button
        mat-stroked-button
        color="primary"
        (click)="openCreateProjectModal(null)"
    >
        Create project
    </button>
</div>
