import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HolidayService} from './holiday.service';
import {HolidayOverviewComponent} from './holiday-overview/holiday-overview.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatButtonModule} from '@angular/material/button';
import {CreateHolidayDialogComponent} from './create-holiday-dialog/create-holiday-dialog.component';
import {FormsModule} from '@angular/forms';
import {MatDialogModule} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        FormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        MatDatepickerModule
    ],
    declarations: [HolidayOverviewComponent, CreateHolidayDialogComponent],
    exports: [],
    providers: [HolidayService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HolidayModule {}
