import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { UserDetails } from "../users/userDetails";
import { Customer } from "../customer/customer";

@Injectable()
export class ReportingService {
    constructor(private http: HttpClient) {}

    downloadReportByUser(
        userId: String,
        exportParams: PdfExportParams
    ): Observable<Blob> {
        const requestParams =
            this.getRequestParamsFromExportParams(exportParams);
        requestParams["locale"] = exportParams.locale;
        requestParams["custSig"] = exportParams.custSig.toString();
        requestParams["ownSig"] = exportParams.ownSig.toString();

        return this.http.get(
            `${environment.backendUrl}/api/reports/byUser/${userId}`,
            { responseType: "blob", params: requestParams }
        );
    }

    downloadReportByCustomer(
        companyId: number,
        exportParams: PdfExportParams
    ): Observable<Blob> {
        const requestParams =
            this.getRequestParamsFromExportParams(exportParams);
        requestParams["locale"] = exportParams.locale;
        requestParams["custSig"] = exportParams.custSig.toString();
        requestParams["ownSig"] = exportParams.ownSig.toString();

        return this.http.get(
            `${environment.backendUrl}/api/reports/byCustomer/${companyId}`,
            { responseType: "blob", params: requestParams }
        );
    }

    downloadExcelExport(exportParams: ExcelExportParams): Observable<Blob> {
        const requestParams =
            this.getRequestParamsFromExportParams(exportParams);
        requestParams["customerIds"] = exportParams.customerIds.map((value) =>
            value.toString()
        );

        return this.http.get(
            `${environment.backendUrl}/api/reports/excelexport`,
            {
                responseType: "blob",
                params: requestParams,
            }
        );
    }

    getRequestParamsFromExportParams(exportParams: ExportParams) {
        return {
            startDay: exportParams.startDay.toString(),
            startMonth: exportParams.startMonth.toString(),
            startYear: exportParams.startYear.toString(),

            endDay: exportParams.endDay.toString(),
            endMonth: exportParams.endMonth.toString(),
            endYear: exportParams.endYear.toString(),
        };
    }
}

export class ExportParams {
    startDay: number;
    startMonth: number;
    startYear: number;
    endDay: number;
    endMonth: number;
    endYear: number;
}

export class PdfExportParams extends ExportParams {
    public user: UserDetails;
    public customer: Customer;
    locale: string = "de-DE";
    custSig: boolean = true;
    ownSig: boolean = true;
}

export class ExcelExportParams extends ExportParams {
    customerIds: Number[];
}
