import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    TimeSheetFilterParams,
    TimetrackingService,
} from "../timetracking.service";
import { ProjectService } from "../../projects/project.service";
import * as moment from "moment";
import { Moment } from "moment";
import { TimesheetEntryDTO, TimesheetEntryTableItem } from "../timesheet-entry";
import { CreateTimesheetEntryDialogComponent } from "../create-timesheet-entry-dialog/create-timesheet-entry-dialog.component";
import { MonthYearModel } from "../../common";
import "rxjs/add/operator/mergeMap";
import "rxjs/add/operator/mergeAll";
import "rxjs/add/operator/groupBy";
import "rxjs/add/operator/map";
import "rxjs/add/operator/reduce";
import "rxjs/add/operator/zip";
import "rxjs/add/observable/from";
import "rxjs/add/operator/toArray";
import { Observable } from "rxjs";
import { UserProject } from "../../projects/project";
import { ProjectRoleDTO } from "../../projects/project-role";
import { ProjectRoleService } from "../../projects/project-role.service";
import { MatDialog } from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";

@Component({
    selector: "app-timesheet-details",
    templateUrl: "./timesheet-overview.component.html",
    styleUrls: ["./timesheet-overview.component.sass"],
})
export class TimesheetOverviewComponent implements OnInit {
    timesheetEntriesFilter: TimesheetEntriesFilter =
        new TimesheetEntriesFilter();

    calendarWeekEntries: CalendarWeekEntries[] = undefined;
    sumTotal: number;
    availableProjects: Observable<UserProject[]>;
    availableRoles: Observable<ProjectRoleDTO[]>;
  years: number[] = [new Date().getFullYear() - 2017];

    constructor(
        private activatedRoute: ActivatedRoute,
        private timetrackingService: TimetrackingService,
        private projectService: ProjectService,
        private projectRoleService: ProjectRoleService,
        private dialog: MatDialog
    ) {}

  ngOnInit() {
    this.reloadEntries();
    this.availableProjects = this.projectService
    .readMyProjects()
    .map((projects) =>
    projects
        .filter((p) => !p.hidden)
        .sort((a, b) =>
          (a.customerName + a.name) > (b.customerName + b.name)
          ? 1
          : -1
          )
        .sort((a, b) => {
          if (a.favorite === b.favorite) {
            return 0;
          } else {
            return !a.favorite ? 1 : -1;
          }
        })
    );
    this.availableRoles =
     this.projectRoleService.readAllAssignedProjectRoles(
              this.timesheetEntriesFilter.selectedProjects
              );
    this.fillInYears();
  }

    toHours(durationMinutes: number): string {
        const minutes = durationMinutes % 60;
        const hours = (durationMinutes - minutes) / 60;

        const hoursString = hours > 9 ? hours : "0" + hours;
        const minutesString = minutes > 9 ? minutes : "0" + minutes;

        return `${hoursString}:${minutesString}`;
    }

  openAddDialog() {
          this.openDialog(null, false);
      }

      openEditDialog(element: TimesheetEntryDTO) {
          this.openDialog(element, true);
      }

      openDuplicateDialog(element: TimesheetEntryDTO, event: MouseEvent) {
          event.stopPropagation();
          this.openDialog(element, false);
      }

      deleteEntry(element: TimesheetEntryDTO, event: MouseEvent) {
          event.stopPropagation();
          this.timetrackingService
              .deleteTimesheetEntry(element.id)
              .subscribe(() => {
                  this.reloadEntries();
              });
      }

      openDialog(element: TimesheetEntryDTO | null, edit: boolean) {
          this.dialog
              .open(CreateTimesheetEntryDialogComponent, {
                  data: { element, edit },
                  maxWidth: 500,
                  disableClose: true,
              })
              .afterClosed()
              .subscribe((result) => {
                  if (result) {
                      this.reloadEntries();
                  }
              });
      }

    projectFilterChanged() {
        this.timesheetEntriesFilter.selectedRoles = [];
        this.availableRoles =
            this.projectRoleService.readAllAssignedProjectRoles(
                this.timesheetEntriesFilter.selectedProjects
            );

        this.reloadEntries();
    }

    reloadEntries() {
        switch (this.timesheetEntriesFilter.filterMode) {
            case FilterMode.BASIC:
                this.reloadEntriesBasic();
                break;
            case FilterMode.EXTENDED:
                this.reloadEntriesExtended();
                break;
        }
    }

    reloadEntriesBasic() {
        const timeSheetFilterParams: TimeSheetFilterParams =
            new TimeSheetFilterParams();
        timeSheetFilterParams.startDay = 1;
        timeSheetFilterParams.startMonth =
            this.timesheetEntriesFilter.monthYear.month;
        timeSheetFilterParams.startYear =
            this.timesheetEntriesFilter.monthYear.year;

        timeSheetFilterParams.endDay = moment()
            .year(this.timesheetEntriesFilter.monthYear.year)
            .month(this.timesheetEntriesFilter.monthYear.month - 1)
            .endOf("month")
            .date();
        timeSheetFilterParams.endMonth =
            this.timesheetEntriesFilter.monthYear.month;
        timeSheetFilterParams.endYear =
            this.timesheetEntriesFilter.monthYear.year;

        const entries = this.timetrackingService.readTimesheetFilteredEntries(
            timeSheetFilterParams
        );

        this.groupEntries(entries);
    }

    reloadEntriesExtended() {
        const timeSheetFilterParams: TimeSheetFilterParams =
            new TimeSheetFilterParams();
        timeSheetFilterParams.startDay =
            this.timesheetEntriesFilter.start.date();
        timeSheetFilterParams.startMonth =
            this.timesheetEntriesFilter.start.month() + 1;
        timeSheetFilterParams.startYear =
            this.timesheetEntriesFilter.start.year();

        timeSheetFilterParams.endDay = this.timesheetEntriesFilter.end.date();
        timeSheetFilterParams.endMonth =
            this.timesheetEntriesFilter.end.month() + 1;
        timeSheetFilterParams.endYear = this.timesheetEntriesFilter.end.year();

        timeSheetFilterParams.projectIds =
            this.timesheetEntriesFilter.selectedProjects;
        timeSheetFilterParams.roleIds =
            this.timesheetEntriesFilter.selectedRoles;

        const entries = this.timetrackingService.readTimesheetFilteredEntries(
            timeSheetFilterParams
        );

        this.groupEntries(entries);
    }

    groupEntries(entries: Observable<TimesheetEntryDTO[]>) {
        this.calendarWeekEntries = [];
        this.sumTotal = 0;

        entries.subscribe({
            next: (value) => {
                if (value.length > 0) {
                    Observable.from(value)
                        .map((it) => new TimesheetEntryTableItem(it))
                        .groupBy((it) => it.date.isoWeek())
                        .flatMap((weekGroup) =>
                            weekGroup
                                .groupBy((it) => it.date.unix())
                                .flatMap((dayGroup) =>
                                    dayGroup
                                        .reduce(
                                            (acc, cur) => [...acc, cur],
                                            [dayGroup.key]
                                        )
                                        .map(
                                            (dayGroup) =>
                                                new DayEntries(
                                                    moment(
                                                        dayGroup[0] as number
                                                    ),
                                                    dayGroup.splice(
                                                        1
                                                    ) as TimesheetEntryTableItem[]
                                                )
                                        )
                                )
                                .reduce(
                                    (acc, cur) => [...acc, cur],
                                    [weekGroup.key]
                                )
                                .map(
                                    (weekGroup) =>
                                        new CalendarWeekEntries(
                                            weekGroup[0] as number,
                                            weekGroup.splice(1) as DayEntries[]
                                        )
                                )
                        )
                        .toArray()
                        .subscribe((items) => {
                            this.calendarWeekEntries = items;
                        });

                    this.sumTotal = value
                        .map((entryDTO) => entryDTO.duration)
                        .reduce((p, c) => p + c)
                        .valueOf();
                }
            },
        });
    }

    sum(entries: TimesheetEntryTableItem[]): string {
        const sum = entries
            .map((entry) => entry.duration)
            .reduce((p, c) => p + c, 0);
        return this.toHours(sum);
    }

    cwSum(entries: CalendarWeekEntries): string {
        return this.sum(
            entries.dayEntries
                .map((it) => it.entries)
                .reduce((prev, curr) => prev.concat(curr), [])
        );
    }

    addMonth(addition: number) {
        const currentMonth = this.timesheetEntriesFilter.monthYear.month;
        const prevMonth = currentMonth + addition;

        if (prevMonth < 1) {
            this.timesheetEntriesFilter.monthYear.year =
                this.timesheetEntriesFilter.monthYear.year - 1;
            this.timesheetEntriesFilter.monthYear.month = 12;
        } else if (prevMonth > 12) {
            this.timesheetEntriesFilter.monthYear.year =
                this.timesheetEntriesFilter.monthYear.year + 1;
            this.timesheetEntriesFilter.monthYear.month = 1;
        } else {
            this.timesheetEntriesFilter.monthYear.month = prevMonth;
        }

        this.reloadEntries();
    }

    addYear(addition: number) {
        this.timesheetEntriesFilter.monthYear.year =
            this.timesheetEntriesFilter.monthYear.year + addition;
        this.reloadEntries();
    }

    filterTabChanged(event: MatTabChangeEvent) {
        if (event.index === 0) {
            this.timesheetEntriesFilter.filterMode = FilterMode.BASIC;
        } else {
            this.timesheetEntriesFilter.filterMode = FilterMode.EXTENDED;
        }
        this.reloadEntries();
    }

  fillInYears() {
    for (let i = 2017; i <= new Date().getFullYear(); i++) {
      this.years[i - 2017] = i;
    }
  }
}

export class CalendarWeekEntries {
    constructor(cw: number, entries: DayEntries[]) {
        this.cw = cw;
        this.dayEntries = entries;
    }

    public cw: number;
    public dayEntries: DayEntries[];
}

export class DayEntries {
    constructor(day: Moment, entries: TimesheetEntryTableItem[]) {
        this.day = day;
        this.entries = entries;
    }

    public day: Moment;
    public entries: TimesheetEntryTableItem[];
}

export class TimesheetEntriesFilter {
    private now: Moment = moment();
    monthYear: MonthYearModel = new MonthYearModel(
        this.now.year(),
        this.now.month() + 1
    );
    start: Moment = moment().startOf("month");

    end: Moment = moment().endOf("month");
    selectedProjects: number[] = [];
    selectedRoles: number[] = [];

    filterMode: FilterMode = FilterMode.BASIC;
}

export enum FilterMode {
    BASIC,
    EXTENDED,
}
