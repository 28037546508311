export interface DataDTO {
    label: string[],
    data: number[]
}

export interface Dataset {
    showInLegend: boolean;
    legendText: string;
    type: string;
    dataPoints: any[];
}

export enum Timespan {
    MONTH = 0,
    QUARTER = 1,
    YEAR = 2
}

export enum HomeCard {
    FAST_ENTRIES,
    USER,
    PROJECTS,
    REVENUE,
    VACATION
}

export enum ReportingPeriod {
    CURRENT_MONTH,
    CURRENT_QUARTER,
    CURRENT_HALFYEAR,
    CURRENT_YEAR,
    LAST_MONTH,
    LAST_QUARTER,
    LAST_HALFYEAR,
    LAST_YEAR
}

export interface ChartInterface {
    title: {
        text: string;
        fontFamily: string;
        fontSize: number;
    };
    backgroundColor: string;
    theme: string;
    data: [{
        type: string;
        dataPoints:
            {
                label: string;
                y: number;
            }[];
    }];
    toolTip: {
        enabled: boolean;
        content: (e: any) => string;
    };


}
