import { Component, OnInit } from "@angular/core";
import { ProjectRoleService } from "../project-role.service";
import { ProjectRoleDTO } from "../project-role";
import { MatDialog } from "@angular/material/dialog";
import { EditDialogComponent } from "./edit-dialog/edit-dialog.component";
import { Observable } from "rxjs";

@Component({
    selector: "app-project-roles-overview",
    templateUrl: "./project-roles-overview.component.html",
    styleUrls: ["./project-roles-overview.component.sass"],
})
export class ProjectRolesOverviewComponent implements OnInit {
    public projectRoles: Observable<ProjectRoleDTO[]>;

    constructor(
        private projectRoleService: ProjectRoleService,
        private matDialog: MatDialog
    ) {}

    ngOnInit() {
        this.updateRoles();
    }

    openProjectRoleDialog(projectToBeEdited: ProjectRoleDTO | null) {
        this.matDialog
            .open(EditDialogComponent, {
                data: projectToBeEdited,
                minWidth: 600,
            })
            .afterClosed()
            .subscribe((next) => {
                if (next) {
                    this.updateRoles();
                }
            });
    }

    private updateRoles() {
        this.projectRoles = this.projectRoleService
            .readAll()
            .map((roles) =>
                roles.sort((a, b) =>
                    a.invoiceReference > b.invoiceReference ? 1 : -1
                )
            );
    }
}
