import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { UsersModule } from "../users/users.module";
import { ProjectService } from "../projects/project.service";
import { TimetrackingService } from "./timetracking.service";
import { RouterModule } from "@angular/router";
import { TimesheetOverviewComponent } from "./timesheet-overview/timesheet-overview.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CreateTimesheetEntryDialogComponent } from "./create-timesheet-entry-dialog/create-timesheet-entry-dialog.component";
import { CustomerService } from "../customer/customer.service";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
import { MatListModule } from "@angular/material/list";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatCardModule } from "@angular/material/card";
import { MatRippleModule } from "@angular/material/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTabsModule } from "@angular/material/tabs";
import { MatButtonToggleModule } from "@angular/material/button-toggle";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        UsersModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatTableModule,
        MatListModule,
        RouterModule,
        FlexLayoutModule,
        MatDialogModule,
        MatTooltipModule,
        MatChipsModule,
        MatDatepickerModule,
        MatCardModule,
        MatRippleModule,
        MatBadgeModule,
        MatExpansionModule,
        MatTabsModule,
        MatButtonToggleModule,
    ],
    declarations: [
        TimesheetOverviewComponent,
        CreateTimesheetEntryDialogComponent,
    ],
    exports: [],
    providers: [ProjectService, TimetrackingService, CustomerService],
    entryComponents: [CreateTimesheetEntryDialogComponent],
})
export class TimetrackingModule {}
