<form #projectForm="ngForm" (ngSubmit)="saveProject()">
    <h2 mat-dialog-title>
        {{ mode === Mode.CREATE ? "Create" : "Edit" }} Project
    </h2>
    <mat-dialog-content>
        <mat-form-field style="width: 100%">
            <mat-label>Customer</mat-label>
            <mat-select
                required
                id="customer"
                name="customer"
                [(ngModel)]="model.customerId"
            >
                <mat-option
                    *ngFor="let customer of availableCustomers"
                    class="left circle"
                    [value]="customer.id"
                >
                    {{ customer.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <mat-label>Project Name</mat-label>
            <input
                matInput
                [(ngModel)]="model.name"
                id="project-name"
                name="project-name"
                type="text"
                required
            />
        </mat-form-field>

        <div fxLayout="row" fxLayoutAlign="space-between">
            <mat-form-field fxFlex="1 1 48%">
                <mat-label>Start date</mat-label>
                <input
                    matInput
                    id="startDate"
                    name="startDate"
                    [matDatepicker]="startPicker"
                    [(ngModel)]="model.start"
                    required
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="startPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex="1 1 48%">
                <mat-label>End date</mat-label>
                <input
                    matInput
                    id="endDate"
                    name="endDate"
                    [matDatepicker]="endPicker"
                    [(ngModel)]="model.end"
                    required
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="endPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
        </div>

        <mat-form-field style="width: 100%">
            <mat-label>Entry Hint</mat-label>
            <input
                matInput
                [(ngModel)]="model.entryHint"
                id="entry-hint"
                name="entry-hint"
                type="text"
            />
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button
            type="button"
            *ngIf="mode === Mode.EDIT"
            mat-button
            color="warn"
            (click)="deleteProject()"
        >
            Delete
        </button>
        <button
            mat-stroked-button
            type="submit"
            [disabled]="!projectForm.valid"
        >
            Save
        </button>
        <button
            type="button"
            mat-stroked-button
            style="margin-left: 10px"
            [mat-dialog-close]="false"
        >
            Cancel
        </button>
    </mat-dialog-actions>
</form>
