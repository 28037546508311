<div id="container" class="container">
    <div class="moveCalenderViewPortUpButton">
        <button
            id="up"
            mat-stroked-button
            [disabled]="!this.firstDayOfCalendarPage?.isAfter(this.minViewport)"
            (click)="moveCalendarViewport(-1)"
        >
            V
        </button>
    </div>
    <div class="dayContainer" (mousewheel)="scrollViewport($event)">
        <div class="weekDays">Mo</div>
        <div class="weekDays">Tu</div>
        <div class="weekDays">We</div>
        <div class="weekDays">Th</div>
        <div class="weekDays">Fr</div>
        <div class="weekDays">Sa</div>
        <div class="weekDays">Su</div>

        <ng-container *ngIf="selectedCalendarItems">
            <ng-container *ngFor="let moment of this.calendarDays">
                <div
                    class="day"
                    [ngClass]="processCalendarDayNgClass(moment)"
                    [attr.title]="processCalendarDayTitle(moment)"
                    (mouseenter)="dayEntered(moment, $event)"
                    (mousedown)="dayClicked(moment, $event)"
                    (contextmenu)="preventDefaults($event)"
                >
                    <span>{{
                        moment.date() === 1
                            ? moment.format("D. MMM YYYY").toUpperCase()
                            : moment.date()
                    }}</span>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <div class="moveCalenderViewPortDownButton">
        <button
            id="down"
            mat-stroked-button
            [disabled]="
                !this.firstDayOfCalendarPage
                    ?.clone()
                    .add(6, 'weeks')
                    .isBefore(this.maxViewport)
            "
            (click)="moveCalendarViewport(1)"
        >
            V
        </button>
    </div>

    <br />

    <div id="legend" fxLayoutAlign="flex-start">
        <div fxLayoutAlign="start center" fxLayoutGap="10px">
            <div class="legendWholeDay"></div>
            <div>whole day</div>
        </div>
        <div fxLayoutAlign="start center" fxLayoutGap="10px">
            <div class="legendFirstHalf"></div>
            <div>first half</div>
        </div>
        <div fxLayoutAlign="start center" fxLayoutGap="10px">
            <div class="legendSecondHalf"></div>
            <div>second half</div>
        </div>
    </div>
</div>
