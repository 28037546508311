<h2 mat-dialog-title id="title">
    {{ mode === Mode.DISPLAY ? "Display" : "Verify" }} Vacation
</h2>
<mat-dialog-content *ngIf="vacationModel"
    >{{ vacationModel.userName }} [{{
        vacationModel.userId
    }}]</mat-dialog-content
>
<br />

<app-calendar
    mat-dialog-content
    *ngIf="calendarItems"
    [calendarItems]="calendarItems"
    [minViewport]="minViewport"
    [maxViewport]="maxViewport"
    [focusDay]="focusDay"
    [allowSelection]="false"
></app-calendar>
<mat-dialog-actions>
    <div id="actions" *ngIf="mode !== Mode.DISPLAY">
        <button
            id="verifyWIP"
            *ngIf="this.vacationModel?.state !== VacationState.WIP"
            mat-stroked-button
            (click)="verifyVacation(VacationState.WIP)"
        >
            WIP
        </button>

        <button
            id="verifyAPPROVED"
            *ngIf="this.vacationModel?.state !== VacationState.APPROVED"
            mat-stroked-button
            color="primary"
            type="submit"
            (click)="verifyVacation(VacationState.APPROVED)"
        >
            Accept
        </button>

        <button
            id="verifyDECLINED"
            *ngIf="this.vacationModel?.state !== VacationState.DECLINED"
            mat-stroked-button
            color="warn"
            type="submit"
            (click)="verifyVacation(VacationState.DECLINED)"
        >
            Decline
        </button>
    </div>

    <button
        id="exit"
        style="margin-left: 8px"
        mat-stroked-button
        [mat-dialog-close]="false"
    >
        {{ mode === Mode.DISPLAY ? "Close" : "Cancel" }}
    </button>
</mat-dialog-actions>
