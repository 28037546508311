import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProjectModel } from "../../project";

@Component({
    selector: "app-project-admin-delete-confirm-dialog",
    templateUrl: "./project-admin-delete-confirm-dialog.component.html",
    styleUrls: ["./project-admin-delete-confirm-dialog.component.scss"],
})
export class ProjectAdminDeleteConfirmDialogComponent implements OnInit {
    public model: ProjectModel;

    constructor(@Inject(MAT_DIALOG_DATA) public project: ProjectModel) {
        this.model = this.project;
    }

    ngOnInit() {}
}
