import { Component, OnInit } from "@angular/core";
import { ProjectService } from "../project.service";
import { ProjectModel } from "../project";
import { Router } from "@angular/router";
import { CreateProjectDialogComponent } from "./create-project-dialog/create-project-dialog.component";
import { CustomerService } from "../../customer/customer.service";
import { Observable } from "rxjs";
import { Customer } from "../../customer/customer";
import { flatMap, map, tap, toArray } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";

@Component({
    selector: "app-project-admin-overview",
    templateUrl: "./project-admin-overview.component.html",
    styleUrls: ["./project-admin-overview.component.sass"],
})
export class ProjectAdminOverviewComponent implements OnInit {
    public projects: Observable<ProjectModel[]>;

    // pagination
    public pageSize: Number = 10;
    public pageIndex: Number = 0;
    public totalProjects: Number;

    // customer filter
    public customers: Observable<Customer[]>;
    public customerId: Number | undefined;
    public states: string[];

    constructor(
        private projectService: ProjectService,
        private customerService: CustomerService,
        private router: Router,
        private dialog: MatDialog,
        private compensationDiaglog: MatDialog
    ) {}

    ngOnInit() {
        const pageIndex = sessionStorage.getItem("pageIndex");
        if (pageIndex) {
            this.pageIndex = +pageIndex;
        }
        const pageSize = sessionStorage.getItem("pageSize");
        if (pageSize) {
            this.pageSize = +pageSize;
        }
        const customerIdJson = sessionStorage.getItem("customerId");
        if (customerIdJson) {
            this.customerId = +customerIdJson;
        }
        const statesJson = sessionStorage.getItem("states");
        if (statesJson) {
            this.states = JSON.parse(statesJson);
        }

        this.reloadProjects();
        this.customers = this.customerService.readCustomers();
    }

    openCreateProjectModal(project: ProjectModel) {
        this.dialog
            .open(CreateProjectDialogComponent, {
                data: project,
                disableClose: true,
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.reloadProjects();
                }
            });
    }

    public reloadProjects() {
        this.projects = this.projectService
            .readAllProjects(
                this.pageIndex,
                this.pageSize,
                this.customerId,
                this.states
            )
            .pipe(
                tap((page) => (this.totalProjects = page.totalElements)),
                flatMap((value) => Observable.from(value.content)),
                map((value) => ProjectModel.fromProject(value)),
                toArray(),
                tap((value) => {
                    value.sort((a, b) =>
                        a.customerName + a.name > b.customerName + b.name
                            ? 1
                            : -1
                    );
                    value.sort((a, b) =>
                        a.hasEnded() && !b.hasEnded()
                            ? 1
                            : !a.hasEnded() && b.hasEnded()
                            ? -1
                            : 0
                    );
                })
            );
    }

    openProjectMemberManagement(project: ProjectModel) {
        this.router.navigate(["manage/projects/" + project.id + "/members"]);
    }

    openRoleCompensationModal(project: ProjectModel) {
        this.router.navigate(["manage/projects/" + project.id + "/compensation"]);
    }

    pageChanged(pageEvent: PageEvent) {
        this.pageIndex = pageEvent.pageIndex;
        this.pageSize = pageEvent.pageSize;
        sessionStorage.setItem("pageIndex", String(this.pageIndex));
        sessionStorage.setItem("pageSize", String(this.pageSize));
        this.reloadProjects();
    }

    customerFilterChanged() {
        if (this.customerId) {
            sessionStorage.setItem("customerId", String(this.customerId));
        } else {
            sessionStorage.removeItem("customerId");
        }
        this.reloadProjects();
    }

    stateFilterChanged() {
        sessionStorage.setItem("states", JSON.stringify(this.states));
        this.reloadProjects();
    }
}
