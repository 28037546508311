import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HolidayDto, HolidaySaveModel} from './holiday';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import * as moment from 'moment';
import {Moment} from 'moment';
import {ApiFeiertag, ApiFeiertagResponse} from './api-feiertag';
import {Region} from '../regions/region';

@Injectable()
export class HolidayService {
    constructor(private http: HttpClient) {
    }

    readHolidays(minDate: Moment, maxDate: Moment, regionId: number): Observable<HolidayDto[]> {
        const params = {
            minDate: minDate.format('YYYY-MM-DD'),
            maxDate: maxDate.format('YYYY-MM-DD')
        };

        if (regionId) {
            params['regionId'] = regionId;
        }

        return this.http.get<HolidayDto[]>(`${environment.backendUrl}/api/holidays`, {
            params: params
        });
    }

    saveHoliday(holiday: HolidaySaveModel): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            try {
                this.http.post(`${environment.backendUrl}/api/holidays`, holiday).subscribe(() => {
                    resolve();
                });
            } catch (e) {
                reject(new Error(e));
            }
        });
    }

    deleteHoliday(holiday: HolidaySaveModel): Observable<any> {
        return this.http.delete<Observable<any>>(`${environment.backendUrl}/api/holidays/${holiday.id}`);
    }

    importPublicHolidays(year: number, region: Region, consumeApiFeiertag: (arg0: ApiFeiertag) => Promise<void>, finalCallback: () => void) {
        this.http.get<ApiFeiertagResponse>('https://get.api-feiertage.de', {
            params: {
                years: year,
                states: region.code
            }
        }).subscribe(async (next) => {
            if (next.status !== 'success') {
                throw new Error(`API-Feiertage call failed: (${next.status}) ${next.error_description}`);
            }

            for (const apiFeiertag of next.feiertage) {
                await consumeApiFeiertag(apiFeiertag);
            }

            finalCallback();
        });
    }

    deleteAllHolidays(year: number, regionId: number): Observable<any> {
        return this.http.delete(`${environment.backendUrl}/api/holidays`, {
            params: {
                minDate: moment([year, 0, 1]).format('YYYY-MM-DD'),
                maxDate: moment([year, 11, 31]).format('YYYY-MM-DD'),
                regionId: regionId
            }
        });
    }
}
