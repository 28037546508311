import { Component, Inject, OnInit } from "@angular/core";
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBar,
    MatSnackBarRef,
} from "@angular/material/snack-bar";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
    selector: "app-info-snackbar",
    templateUrl: "./info-snackbar.component.html",
    styleUrls: ["./info-snackbar.component.sass"],
})
export class InfoSnackbarComponent implements OnInit {
    data: string;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public snackbarContent: HttpErrorResponse
    ) {
        this.data = "error";
        let errorData = snackbarContent.error;

        if (!JSONUtil.isJson(snackbarContent.error)) {
            errorData = JSON.stringify(snackbarContent.error);
        }

        JSON.parse(errorData, (key, value) => {
            if (key === "message") {
                this.data = value;
            }
        });
    }

    ngOnInit() {}
}

export class InfoSnackbarUtil {
    static getInfoSnackbarComponentMatSnackBarRef(
        snackBar: MatSnackBar,
        err: Error
    ): MatSnackBarRef<InfoSnackbarComponent> {
        return snackBar.openFromComponent(InfoSnackbarComponent, {
            data: err,
            duration: 5000,
            panelClass: ["warning"],
        });
    }
}

export class JSONUtil {
    static isJson(string): boolean {
        try {
            const json = JSON.parse(string);
            return typeof json === "object";
        } catch (e) {
            return false;
        }
    }
}
