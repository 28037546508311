import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MsalService} from '@azure/msal-angular';
import {HomeService} from './home.service';
import {Observable, ReplaySubject} from 'rxjs';
import {ChartInterface, DataDTO, Dataset, HomeCard, ReportingPeriod, Timespan} from './home';
import {ProjectService} from '../projects/project.service';
import {ProjectRoleService} from '../projects/project-role.service';
import {UserProject} from '../projects/project';
import {TimesheetEntryCreateDTO} from '../timetracking/timesheet-entry';
import {TimetrackingService} from '../timetracking/timetracking.service';
import {ProjectRoleDTO} from '../projects/project-role';
import {UserService} from '../users/user.service';
import {UserDetails} from '../users/userDetails';
import {ReportingService} from '../reporting/reporting.service';
import {AbsenceService} from '../absence/absence.service';
import {AbsenceDTO, AbsenceModel} from '../absence/absence';
import {PdfExportModel, ReportingOverviewComponent} from '../reporting/reporting-overview/reporting-overview.component';
import {StepperSelectionEvent} from '@angular/cdk/stepper';
import {CreateVacationRequestDialogComponent, CreateVacationRequestDialogData} from '../absence/create-vacation-request-dialog/create-vacation-request-dialog.component';
import {CreateAbsenceDialogComponent, CreateAbsenceDialogComponentData} from '../absence/create-absence-dialog/create-absence-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Mode} from '../mode';

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.sass"],
})
export class HomeComponent implements OnInit {
    constructor(private msalService: MsalService, private router: Router,
    private homeService: HomeService, private projectService: ProjectService,
    private timetrackingService: TimetrackingService, private userService: UserService,
    private projectRoleService: ProjectRoleService,  private reportingOverview: ReportingOverviewComponent,
    private reportingService: ReportingService, private absenceService: AbsenceService, private dialog: MatDialog) {}

    me: ReplaySubject<UserDetails>;

    //Alle Daten nach Kategorie
    userWorkData: Observable<DataDTO>;
    userHoursPerProjectData: Observable<DataDTO>;
    userProjectMemberData: Observable<DataDTO>;
    projectCustomerData: Observable<DataDTO>;
    projectDistributionData: Observable<DataDTO>;
    projectOverTimeData: Observable<DataDTO>;
    revenueProjectData: Observable<DataDTO>;
    revenueByTimespanData: Observable<DataDTO>;
    absenceData: Observable<DataDTO>;
    absenceThisWeekData: Observable<DataDTO[]>;
    absenceVacationDaysLeftData: Observable<DataDTO[]>;

    //Alle Beschriftungen nach Kategorie
    userTitleWorkingHours: string;
    userTitleHoursPerProject: string;
    projectTitle: string;
    revenueTitlePerProjectInEuro: string;
    revenueTitleInEuro: string;

    //Vacation-Daten
    dataReady = false;

    absenceName: string;
    selectedAbsence: DataDTO;
    showOnlyPresent: boolean;

    //Sichtbarkeit der Cards
    visibility = Object.values(HomeCard);

    //Parameter für Daten Fetching
    userTimespan = Timespan.MONTH;
    projectTimespan = Timespan.MONTH;
    revenueTimespan = Timespan.MONTH;

    //Schnellerfassung Timesheet
    showCheckmark = false;
    hours = 0;
    minutes = 0;
    currentDate = new Date();

    favProject$: Observable<UserProject>;
    mostUsedRole$: Observable<ProjectRoleDTO>;

    favProject: UserProject;
    mostUsedRole: ProjectRoleDTO;
    task = "";

    allRoles: Observable<ProjectRoleDTO[]>;

    //Schnellerfassung Reporting
    showCheckmark2 = false;
    reportingPeriod: ReportingPeriod;
    reportingProject: UserProject;
    user: UserDetails;
    language: string;

    reportingPeriods = [
        {value: ReportingPeriod.CURRENT_MONTH, name: 'Current Month'},
        {value: ReportingPeriod.CURRENT_QUARTER, name: 'Current Quarter'},
        {value: ReportingPeriod.CURRENT_HALFYEAR, name: 'Current Half-Year'},
        {value: ReportingPeriod.CURRENT_YEAR, name: 'Current Year'},
        {value: ReportingPeriod.LAST_MONTH, name: 'Last Month'},
        {value: ReportingPeriod.LAST_QUARTER, name: 'Last Quarter'},
        {value: ReportingPeriod.LAST_HALFYEAR, name: 'Last Half-Year'},
        {value: ReportingPeriod.LAST_YEAR, name: 'Last Year'}
    ];
    myProjects: Observable<UserProject[]>;
    users: Observable<UserDetails[]>;
    reporter: Observable<UserDetails>;
    languages = [{value: 'de-DE', name: 'German (Germany)'}, {value: 'en-GB', name: 'English (UK)'},
    {value: 'en-US', name: 'English (US)'}];

    //Schnellerfassung Vacation & Absence
    fastAbsenceOb: Observable<AbsenceDTO>;

    ngOnInit(): void {
        if (this.msalService.instance.getAllAccounts().length > 0) {
            //this.router.navigateByUrl("/timesheet");
        }

        this.me = this.userService.me;

        this.reloadUsers();
        this.reloadProjects();
        this.reloadVacation();
        this.reloadRevenue();

        this.initFastEntryData();

    }

    /**
     Schnellerfassung
     */
     initFastEntryData(){
        this.initTimesheet();
        this.initReporting();
     }

    onStepChange(m: UserDetails,  projects?: UserProject[], event?: StepperSelectionEvent){
        this.initAbsence(m);
    }

    initTimesheet(){
        this.favProject$ = this.homeService.getDefaultProject();
                this.favProject$.subscribe(
                    project => {
                        if (project) {
                            this.favProject = project;
                            this.mostUsedRole$ = this.homeService.getMostUsedRole(project.id);
                            this.allRoles = this.projectRoleService.getCompensatedRolesForProject(this.favProject.id);
                        }
                    },
                    err => console.log(err),
                    () => {
                        if (this.mostUsedRole$) {
                            this.mostUsedRole$.subscribe(
                                role => this.mostUsedRole = role
                            )
                        }
                    }
                )
    }

    initReporting(){
        this.myProjects = this.projectService.readMyProjects();
                this.users = this.userService.readUsers();
                this.reporter = this.userService.readMe();

                //Select active user as default for reporting
                this.reporter.subscribe(
                    r => this.user = r
                )
    }

    initAbsence(m: UserDetails){
        this.fastAbsenceOb = this.absenceService.readCurrentAbsenceByUser(m.principalId);
    }

    downloadReport(){
        this.reportingOverview.pdfExportModel = new PdfExportModel();
        switch(this.reportingPeriod) {
           case ReportingPeriod.CURRENT_MONTH: {
              this.reportingOverview.setStartAndEndDateCurrentMonth(this.reportingOverview.pdfExportModel);
              break;
           }
           case ReportingPeriod.CURRENT_QUARTER: {
              this.reportingOverview.setStartAndEndDateCurrentQuarter(this.reportingOverview.pdfExportModel);
              break;
           }
           case ReportingPeriod.CURRENT_HALFYEAR: {
                this.reportingOverview.setStartAndEndDateCurrentHalfYear(this.reportingOverview.pdfExportModel);
                break;
           }
           case ReportingPeriod.CURRENT_YEAR: {
                this.reportingOverview.setStartAndEndDateCurrentYear(this.reportingOverview.pdfExportModel);
                break;
           }
           case ReportingPeriod.LAST_MONTH: {
                this.reportingOverview.setStartAndEndDateLastMonth(this.reportingOverview.pdfExportModel);
                break;
           }
           case ReportingPeriod.LAST_QUARTER: {
                this.reportingOverview.setStartAndEndDateLastQuater(this.reportingOverview.pdfExportModel);
                break;
           }
           case ReportingPeriod.LAST_HALFYEAR: {
                this.reportingOverview.setStartAndEndDateLastHalfYear(this.reportingOverview.pdfExportModel);
                break;
           }
           case ReportingPeriod.LAST_YEAR: {
                 this.reportingOverview.setStartAndEndDateLastYear(this.reportingOverview.pdfExportModel);
                 break;
           }
        }
        this.reportingOverview.pdfExportModel.user = this.user;
        this.reportingOverview.pdfExportModel.locale = this.language;

        this.reportingOverview.downloadByUser();
        this.showCheckmark2 = true;
    }

    saveEntry() {
            const createDTO: TimesheetEntryCreateDTO = {
                projectId: this.favProject.id,
                roleId: this.mostUsedRole.id,
                year: this.currentDate.getFullYear(),
                month: this.currentDate.getMonth() + 1,
                day: this.currentDate.getDate(),
                duration: this.hours * 60 + this.minutes,
                description: this.task,
            };
            this.timetrackingService.saveTimesheetEntry(createDTO).subscribe(
                {
                    complete: () =>
                        {
                        this.showCheckmark = true;
                        this.reloadUsers();
                        this.reloadRevenue();
                        },
                }
            );
        }


    extendAbsence(m: UserDetails, extend: number){
        this.absenceService.extendAbsence(m.principalId, extend).subscribe(
            () => this.onStepChange(m)
        );
    }

    openAbsenceDialog(m: UserDetails){
        const createAbsenceDialogComponentData: CreateAbsenceDialogComponentData =
                        {
                            absence: new AbsenceModel(
                                                 null,
                                                 null,
                                                 null,
                                                 null,
                                                 null,
                                                 m.principalId,
                                                 m.displayName,
                                                 null
                                             ),
                            mode: Mode.CREATE,
                            hrRole: false
                        };

        this.dialog
                    .open(CreateAbsenceDialogComponent, {
                        data: createAbsenceDialogComponentData,
                        disableClose: true,
                    })
                    .afterClosed()
                    .subscribe(() => this.onStepChange(m));
            }


    openVacationRequestDialog(){
        const createVacationRequestDialogData: CreateVacationRequestDialogData =
                    {
                        hrRole: false,
                        mode: Mode.CREATE,
                    };

                this.dialog
                    .open(CreateVacationRequestDialogComponent, {
                        data: createVacationRequestDialogData,
                        disableClose: true,
                    })
                    .afterClosed()
                    .subscribe();
            }

    /**
     Datenfetching für Charts
     */
    reloadUsers() {
        const timespan = this.userTimespan == Timespan.MONTH ? this.getCurrentMonth() : new Date().getFullYear();
        this.userTitleWorkingHours = 'Working hours (' + timespan + ')';
        this.userTitleHoursPerProject = 'Hours per Project (' + timespan + ')';

        this.userWorkData = this.homeService.getWorkData(this.userTimespan);
        this.userHoursPerProjectData = this.homeService.getHoursPerProjectData(this.userTimespan);
        this.userProjectMemberData = this.homeService.getProjectMemberData();

    }

    reloadProjects(){
        const suffix = this.projectTimespan == Timespan.MONTH ? "month (" + new Date().getFullYear() + ")" : "year";
        this.projectTitle = "Projects per " + suffix;

        this.projectCustomerData = this.homeService.getCustomerProjectData();
        this.projectDistributionData = this.homeService.getProjectDistributionData();
        this.projectOverTimeData = this.homeService.getProjectOverTimeData(this.projectTimespan);
    }

    reloadRevenue() {
        if (this.revenueTimespan == Timespan.MONTH) {
            this.revenueTitlePerProjectInEuro = 'Revenue per Project in € (' + this.getCurrentMonth() + ')';
            this.revenueTitleInEuro = 'Revenue in € (' + new Date().getFullYear() + ')';
        } else if (this.revenueTimespan == Timespan.QUARTER) {
            this.revenueTitlePerProjectInEuro = 'Revenue per Project in € (current Quarter)';
            this.revenueTitleInEuro = 'Revenue in € (' + new Date().getFullYear() + ')';
        } else if (this.revenueTimespan == Timespan.YEAR) {
            this.revenueTitlePerProjectInEuro = 'Revenue per Project in € (' + new Date().getFullYear() + ')';
            this.revenueTitleInEuro = 'Revenue in € (last 3 years)';
        }

        this.revenueProjectData = this.homeService.getProjectsRevenue(this.revenueTimespan);
        this.revenueByTimespanData = this.homeService.getRevenueByTimespan(this.revenueTimespan);

    }

    reloadVacation(){
        this.absenceData = this.homeService.getAbsenceData();
        this.absenceThisWeekData = this.homeService.getAbsenceThisWeek();
        this.absenceVacationDaysLeftData = this.homeService.getVacationDaysLeft();
    }

    /**
     Berechnungen auf Basis der Chart Daten
     */
    getOvertime(data: number): number{
        let goal = 120;
        let overtime = 0;

        if (this.userTimespan){
            goal *= 12;
        }

       overtime = data - goal;
       return overtime
    }

    getTotalRevenueByProject(dataDto: DataDTO): DataDTO{
        let count = 0;
        let best = 0;
        let worst = 0;
        let current = dataDto.data[dataDto.data.length - 1];

        let result: DataDTO = {
            label: [],
            data: []
        };

        dataDto.data.forEach(
            (data, index) => {
                count += data;
                    if (data > best){
                        best = data
                        result.label[0] = dataDto.label[index]
                    }
                    if (data < worst || worst == 0){
                        worst = data
                        result.label[1] = dataDto.label[index]
                    }
                }
        )
        result.data[0] = +count.toFixed(2);
        result.data[1] = +best.toFixed(2);
        result.data[2] = +worst.toFixed(2);

        return result;
    }

    getTotalRevenueByTimespan(dataDto: DataDTO): DataDTO{
            let count = 0;
            let best = 0;
            let worst = 0;
            let current = dataDto.data[dataDto.data.length - 1];

            let result: DataDTO = {
                label: [],
                data: []
            };

            dataDto.data.forEach(
                (data, index) => {
                    count += data;
                        if (data > best){
                            best = data
                            result.label[0] = dataDto.label[index]
                        }
                        if (data < worst || worst == 0){
                            worst = data
                            result.label[1] = dataDto.label[index]
                        }
                    }
            )
            result.data[0] = +count.toFixed(2);
            result.data[1] = +best.toFixed(2);
            result.data[2] = +worst.toFixed(2);
            result.data[3] = +current.toFixed(2);

            result.label[2] = dataDto.label[dataDto.data.length - 1]; // Current Month

            return result;

        }

    changeAbsenceSelection(absences: DataDTO){
            this.selectedAbsence = {
                label: [],
                data: []
            };

            absences.label.forEach(
                (absence, index) =>
                {
                    if(absence.includes(this.absenceName))
                    {
                        this.selectedAbsence.label.push(absence);
                        this.selectedAbsence.data.push(absences.data[index]);
                    }
                }
            )
        }

    getCssClasses(value: number): string[]{
        let classes: string[] = [];
        classes.push('absence-base');

        switch (value) {
              case 1:
                 classes.push('absence-red'); //ILLNESS
                 if(this.showOnlyPresent){
                    classes.push('invisible')
                 }
                 break;
              case 2:
                 classes.push('absence-violet'); //BUSINESSTRIP
                 if(this.showOnlyPresent){
                    classes.push('invisible')
                 }
                 break;
              case 3:
                classes.push('absence-orange'); //VACATION
                if(this.showOnlyPresent){
                    classes.push('invisible')
                }
                break;
              default:
                classes.push('absence-green') //PRESENT
            }

        return classes;
    }

    /**
     Verhalten der Buttons unter Charts
     */
    openUsers(){
        this.router.navigateByUrl("/manage/users");
    }

    openProjects(){
        this.router.navigateByUrl("/manage/projects");
    }

    openCustomers(){
        this.router.navigateByUrl("/manage/customers")
    }

    openAbsences(){
        this.router.navigateByUrl("/manage/absences")
    }

    /**
     Bauen der Charts
     */
    buildSingleChart(data: DataDTO, title: string, type: string, unit?: string): ChartInterface {

        let dataPoints = new Array();

        for (let i = 0; i < data.label.length; i++) {
            dataPoints.push({label: data.label[i], y: data.data[i]})
        }

        let chart: ChartInterface = {
                    title: {
                        text: title,
                        fontFamily: "Roboto",
                        fontSize: 25
                    },
                    backgroundColor: "#595959",
                    theme: "dark2",
                    data: [{
                        type: type,
                        dataPoints: dataPoints,
                    }],
                    toolTip: {
                        enabled: true,
                        content: function(e) {
                            let content = "";
                            for (let i = 0; i < e.entries.length; i++) {
                                if(unit){
                                    content += e.entries[i].dataPoint.label + ": " + e.entries[i].dataPoint.y.toFixed(2) + ' ' + unit;
                                } else {
                                    content += e.entries[i].dataPoint.label + ": " + e.entries[i].dataPoint.y;
                                }
                                content += "<br/>";
                            }
                            return content;
                        }
                    }
                };
                return chart
    }

    buildMultipleChart(dataDTO: DataDTO[], title: string, type: string, legendTexts?: string[], unit?: string): any {

            let dataPoints = new Array();
            let dataset: Dataset[] = new Array() as Dataset[];

            for(let j = 0; j < dataDTO.length; j++){
                dataPoints = new Array();
                    for (let i = 0; i < dataDTO[j].label.length; i++) {
                        dataPoints.push({label: dataDTO[j].label[i], y: dataDTO[j].data[i]})
                    }
                dataset.push({showInLegend: true, legendText: legendTexts[j], type: type, dataPoints: dataPoints})
            }

            let chart = {
                        title: {
                            text: title,
                            fontFamily: "Roboto",
                            fontSize: 25
                        },
                        backgroundColor: "#595959",
                        theme: "dark2",
                        data: dataset,
                        toolTip: {
                            enabled: true,
                            content: function(e) {
                                let content = "";
                                for (let i = 0; i < e.entries.length; i++) {
                                    if(unit){
                                        content += e.entries[i].dataPoint.label + ": " + e.entries[i].dataPoint.y.toFixed(2) + ' ' + unit;
                                    } else {
                                        content += e.entries[i].dataPoint.label + ": " + e.entries[i].dataPoint.y;
                                    }
                                    content += "<br/>";
                                }
                                return content;
                            }
                        }
                    };
                    return chart
        }

    /**
     Hilfsfunktionen
     */
    splitArray(data: DataDTO, chunkSize: number): DataDTO[] {

        if (!data) {
            return [];
        }
        const result: DataDTO[] = new Array<DataDTO>();

        for (let i = 0; i < data.label.length; i += chunkSize) {
            let temp: DataDTO = {
                label: data.label.slice(i, i + chunkSize),
                data: data.data.slice(i, i + chunkSize)
            }

            result.push(temp)
        }
        return result;
    }

    getCurrentWeekNumber(): number {
        const currentDate = new Date()
        const dayNum = currentDate.getUTCDay() || 7;

        currentDate.setUTCDate(currentDate.getUTCDate() + 4 - dayNum);

        const yearStart = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));
        const weekNo = Math.ceil((((currentDate.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);

        return weekNo;
    }

    getCurrentMonth(): string {
        return new Intl.DateTimeFormat("en-US", { month: "long" }).format(new Date())
    }

    protected readonly HomeCard = HomeCard;
    protected readonly Timespan = Timespan;
}
