import { Component, OnDestroy, OnInit } from "@angular/core";
import {
    SchedulerRow,
    SchedulerRowContent,
} from "../../scheduler/scheduler.component";
import * as moment from "moment";
import { Moment } from "moment";
import { AbsenceModel, AbsenceType } from "../absence";
import {
    AbsenceFilter,
    AbsenceFilterParams,
    AbsenceService,
} from "../absence.service";
import { VacationService } from "../vacation.service";
import {
    UsersAvailableVacationDaysModel,
    UsersAvailableVacationDaysModelUtil,
    VacationModel,
    VacationOverviewModel,
    VacationState,
} from "../vacation";
import { CreateVacationRequestDialogComponent } from "../create-vacation-request-dialog/create-vacation-request-dialog.component";
import { Mode } from "../../mode";
import { UserDetails } from "../../users/userDetails";
import { UserService } from "../../users/user.service";
import { CreateAbsenceDialogComponent } from "../create-absence-dialog/create-absence-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { InfoSnackbarUtil } from "../../common/info-snackbar/info-snackbar.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import {TimeRange} from '../../common';

@Component({
    selector: "app-absence-self-service-overview",
    templateUrl: "./absence-self-service-overview.component.html",
    styleUrls: ["./absence-self-service-overview.component.sass"],
})
export class AbsenceSelfServiceOverviewComponent implements OnInit, OnDestroy {
    Mode = Mode;

    year: number;
    private routeQueryParams: Subscription;

    private absences: AbsenceModel[];
    private absencesFilter: AbsenceFilter;
    private vacationOverviewModels: VacationOverviewModel[];
    schedulerRows: SchedulerRow[];
    leftVacationDays: number;
    private me: UserDetails;
    private now: Moment;
    private firstDayOfYear: Moment;
    private lastDayOfYear: Moment;
    usersAvailableVacationDays: UsersAvailableVacationDaysModel;
    UsersAvailableVacationDaysModelUtil = UsersAvailableVacationDaysModelUtil;

    constructor(
        private absenceService: AbsenceService,
        private vacationService: VacationService,
        private userService: UserService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.leftVacationDays = 0;
        this.now = moment();
        this.firstDayOfYear = this.now.clone().startOf("year");
        this.lastDayOfYear = this.now.clone().endOf("year");
    }

    ngOnInit() {
        this.userService.me.subscribe((value) => {
            this.me = value;
            this.year = moment().year();
            this.absencesFilter = new AbsenceFilter();
            this.reloadAbsences();
            this.reloadLeftVacationDays();
        });

        this.routeQueryParams = this.route.queryParams.subscribe((params) => {
            if (params["vacationId"]) {
                this.openVacationDialog(params["vacationId"]);
            }
        });
    }

    ngOnDestroy(): void {
        this.routeQueryParams.unsubscribe();
    }

    protected reloadAbsences(): void {
        const absenceFilterParams = new AbsenceFilterParams();
        this.absencesFilter.selectedTimeRange = new TimeRange(
            moment().set("year", this.year).startOf("year"),
            moment().set("year", this.year).endOf("year")
        );
        absenceFilterParams.userIds = [this.me.principalId];
        absenceFilterParams.startDay =
            this.absencesFilter.selectedTimeRange.start.date();
        absenceFilterParams.startMonth =
            this.absencesFilter.selectedTimeRange.start.month() + 1;
        absenceFilterParams.startYear =
            this.absencesFilter.selectedTimeRange.start.year();

        absenceFilterParams.endDay =
            this.absencesFilter.selectedTimeRange.end.date();
        absenceFilterParams.endMonth =
            this.absencesFilter.selectedTimeRange.end.month() + 1;
        absenceFilterParams.endYear =
            this.absencesFilter.selectedTimeRange.end.year();

        this.absenceService
            .readAbsencesWithoutVacationsByUserAndTimerange(absenceFilterParams)
            .subscribe((absenceDTOs) => {
                this.absences = absenceDTOs.map((absenceDTO) =>
                    AbsenceModel.fromAbsenceDTO(absenceDTO)
                );

                this.vacationService
                    .readVacationOverviewDTOsByUserAndTimerange(
                        absenceFilterParams
                    )
                    .subscribe((vacationOverviewDTOs) => {
                        this.vacationOverviewModels = vacationOverviewDTOs.map(
                            (vacationOverviewDTO) =>
                                VacationOverviewModel.fromVacationOverviewDTO(
                                    vacationOverviewDTO
                                )
                        );

                        this.schedulerRows = this.getSchedulerRows();
                    });
            });
    }

    private getSchedulerRows(): SchedulerRow[] {
        const schedulerRows: SchedulerRow[] = [];
        const types = Object.keys(AbsenceType).map(
            (value) => AbsenceType[value]
        );

        types.forEach((type) => {
            if (type !== AbsenceType.UNDEFINED) {
                const row: SchedulerRow = {
                    name: type,
                    content: [],
                };

                if (type === AbsenceType.VACATION) {
                    this.vacationOverviewModels.forEach(
                        (vacationOverviewModel) => {
                            const content: SchedulerRowContent = {
                                id: vacationOverviewModel.id,
                                title: vacationOverviewModel.type,
                                start: vacationOverviewModel.start,
                                end: vacationOverviewModel.end,
                                clickable: true,
                            };

                            if (
                                vacationOverviewModel.state ===
                                VacationState.OPEN
                            ) {
                                content.color = "#ff8b15";
                            }
                            if (
                                vacationOverviewModel.state ===
                                VacationState.WIP
                            ) {
                                content.color = "#26ab9c";
                            } else if (
                                vacationOverviewModel.state ===
                                VacationState.DECLINED
                            ) {
                                content.color = "#FF0000";
                            }
                            row.content.push(content);
                        }
                    );
                } else {
                    this.absences.forEach((absence) => {
                        if (absence.type === row.name) {
                            const content: SchedulerRowContent = {
                                id: absence.id,
                                title: absence.type,
                                start: absence.start,
                                end: absence.end,
                                clickable: true,
                            };
                            row.content.push(content);
                        }
                    });
                }
                schedulerRows.push(row);
            }
        });
        return schedulerRows;
    }

    private reloadLeftVacationDays(): void {
        this.vacationService
            .readUsersAvailableVacationDayInformation(
                [this.me.principalId],
                this.now.year()
            )
            .subscribe((usersAvailableVacationDaysDTOs) => {
                this.leftVacationDays = 0;
                this.usersAvailableVacationDays =
                    UsersAvailableVacationDaysModel.fromUsersAvailableVacationDaysDTO(
                        usersAvailableVacationDaysDTOs[0]
                    );
                this.usersAvailableVacationDays.vacationDaysInformation.forEach(
                    (value) =>
                        (this.leftVacationDays =
                            this.leftVacationDays + value.numberOfDaysLeft)
                );
            });
    }

    editVacation($event: SchedulerRowContent): void {
        if ($event.title === AbsenceType.VACATION) {
            this.openVacationDialog($event.id);
        } else {
            this.openDisplayAbsenceDialog($event.id);
        }
    }

    private openDisplayAbsenceDialog(absenceId: number): void {
        this.absenceService.readAbsenceById(absenceId).subscribe((value) => {
            this.dialog
                .open(CreateAbsenceDialogComponent, {
                    data: {
                        mode: Mode.DISPLAY,
                        absence: AbsenceModel.fromAbsenceDTO(value),
                    },
                    disableClose: true,
                })
                .afterClosed()
                .subscribe(() => {
                    this.reloadAbsences();
                    this.reloadLeftVacationDays();
                });
        });
    }

    openCreateVacationDialog(): void {
        this.dialog
            .open(CreateVacationRequestDialogComponent, {
                data: { mode: Mode.CREATE },
                disableClose: true,
            })
            .afterClosed()
            .subscribe(() => {
                this.reloadAbsences();
                this.reloadLeftVacationDays();
            });
    }

    private openVacationDialog(vacationId: number) {
        this.vacationService.readVacationById(vacationId).subscribe(
            (vacationDTO) => {
                this.dialog
                    .open(CreateVacationRequestDialogComponent, {
                        data: {
                            vacationModel:
                                VacationModel.fromVacationDTO(vacationDTO),
                            mode:
                                VacationState[vacationDTO.state] ===
                                VacationState.OPEN
                                    ? Mode.EDIT
                                    : VacationState[vacationDTO.state] ===
                                      VacationState.DECLINED
                                    ? Mode.CONFIRM
                                    : Mode.DISPLAY,
                        },
                        disableClose: true,
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.router.navigate(["."], { relativeTo: this.route });
                        this.reloadAbsences();
                        this.reloadLeftVacationDays();
                    });
            },
            (err) => {
                this.router.navigate(["."], { relativeTo: this.route });
                InfoSnackbarUtil.getInfoSnackbarComponentMatSnackBarRef(
                    this.snackBar,
                    err
                );
            }
        );
    }
}
