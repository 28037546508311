<h2 mat-dialog-title>Delete {{ model.id }}</h2>
<mat-dialog-content>
    <p>
        Do you really want to delete project '<i>{{ model.name }}</i
        >'?
    </p>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="0 1 40px">
            <mat-icon class="warn">warning</mat-icon>
        </div>
        <div fxFlex>
            <p class="warn">
                All timesheets and entries of this project will be deleted, too.
            </p>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="false" autofocus>No</button>
    <button
        mat-stroked-button
        style="margin-left: 10px"
        color="warn"
        [mat-dialog-close]="true"
    >
        Yes
    </button>
</mat-dialog-actions>
