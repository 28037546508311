import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProjectService } from "../../project.service";
import { Project, ProjectMemberDTO } from "../../project";
import { MatDialog } from "@angular/material/dialog";
import { UserService } from "../../../users/user.service";
import { ProjectRoleDTO, ProjectRoleCompensationDTO } from "../../project-role";
import { ProjectRoleService } from "../../project-role.service";
import { Observable, combineLatest } from "rxjs";
import { map } from 'rxjs/operators';
import {
    EditCompensationDialogComponent,
    EditCompensationDialogModel,
} from "../project-compensation-overview/edit-compensation-dialog/edit-compensation-dialog.component";


@Component({
    selector: "app-project-compensation-overview",
    templateUrl: "./project-compensation-overview.component.html",
    styleUrls: ["./project-compensation-overview.component.sass"],
})
export class ProjectCompensationOverviewComponent implements OnInit {
    private projectId: number;
    public project: Observable<Project> = Observable.create();
    public projectRoles: Observable<ProjectRoleDTO[]> = Observable.create();

    public assignedProjectRoles: Observable<ProjectRoleDTO[]>;
    public compensations: Observable<number[]>; // TODO: get compensations
    public combinedData$: Observable<ProjectRoleCompensationDTO[]>;

    constructor(
        private route: ActivatedRoute,
        private projectService: ProjectService,
        private projectRoleService: ProjectRoleService,
        private userService: UserService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.route.paramMap.subscribe((data) => {
            this.projectId = +data.get("projectId");
            this.loadProject(this.projectId);
        });

        this.projectRoles = this.projectRoleService.readAll();
        this.combinedData$ = this.projectRoleService.getCompensationsByProject(this.projectId);

    }

    private loadProject(projectId: number | null) {
        if (projectId) {
            this.project = this.projectService.readProject(projectId);
        }
    }

    openEditCompensationModal(role: ProjectMemberDTO, compensation: number) {
        const dialogReference = this.dialog.open(EditCompensationDialogComponent, {
            data: {role: role, compensation: compensation, projectId: this.projectId},
            width: "400px",
        });
        dialogReference.afterClosed().subscribe((result) => {
                    if (result) {
                        this.saveProjectRoleCompensation(result, role != null);
                    }
                });

    }

    saveProjectRoleCompensation(result: EditCompensationDialogModel, wasEdit: boolean){
        this.projectRoleService.saveProjectRoleCompensation(result.roleId, this.projectId, result.compensation).subscribe(
            undefined,
            undefined,
            () => this.combinedData$ = this.projectRoleService.getCompensationsByProject(this.projectId)
        );

    }

    removeFromProject(roleId: number){
        this.projectRoleService.removeRoleFromProject(roleId, this.projectId).subscribe(
            undefined,
            undefined,
            () => this.combinedData$ = this.projectRoleService.getCompensationsByProject(this.projectId)
        );
    }

}
