import { Component, Inject, OnInit } from "@angular/core";
import { ProjectMemberDTO } from "../../../project";
import { UserService } from "../../../../users/user.service";
import { Observable } from "rxjs";
import { UserDetails } from "../../../../users/userDetails";
import { ProjectRoleService } from "../../../project-role.service";
import { ProjectRoleDTO } from "../../../project-role";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "app-edit-member-dialog",
    templateUrl: "./edit-member-dialog.component.html",
    styleUrls: ["./edit-member-dialog.component.sass"],
})
export class EditMemberDialogComponent implements OnInit {
    public isEditMode: boolean;

    public dialogModel: EditMemberDialogModel;

    public users: Observable<UserDetails[]>;
    public roles: Observable<ProjectRoleDTO[]>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {projectMember: ProjectMemberDTO | null, projectId: number},
        private userService: UserService,
        private roleService: ProjectRoleService
    ) {
        this.users = this.userService.readActiveUsers();
        this.roles = this.roleService.getCompensatedRolesForProject(this.data.projectId);
    }

    ngOnInit(): void {
        if (this.data.projectMember) {
            this.isEditMode = true;
            this.dialogModel = {
                principalId: this.data.projectMember.principalId,
                roles: this.data.projectMember.roles.map((r) => r.id),
            };
        } else {
            this.isEditMode = false;
            this.dialogModel = new EditMemberDialogModel();
        }
    }
}

export class EditMemberDialogModel {
    principalId: string;
    roles: number[];
}
