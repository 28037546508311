import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Region} from './region';
import {ApiFeiertagResponse} from '../holidays/api-feiertag';

@Injectable()
export class RegionService {
    constructor(private http: HttpClient) {}

    readRegions(): Observable<Region[]> {
        return this.http.get<Region[]>(`${environment.backendUrl}/api/regions`);
    }

    saveRegion(region: Region) {
        this.http.post(`${environment.backendUrl}/api/regions`, region).subscribe();
    }

    deleteRegion(region: Region): Observable<any> {
        return this.http.delete<Observable<any>>(`${environment.backendUrl}/api/regions/${region.id}`);
    }

    verifyRegionCode(code: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            try {
                this.http.get<ApiFeiertagResponse>(`https://get.api-feiertage.de`, {
                    params: {
                        states: code
                    }
                }).subscribe((next) => {
                    if (next.status === "success") {
                        resolve(true);
                    } else if (next.status === "error") {
                        resolve(false);
                    } else {
                        reject(new Error("Unknown response status"));
                    }
                });
            } catch (e) {
                reject(new Error(e));
            }
        });
    }
}
