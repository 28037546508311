import * as moment from "moment";
import { Moment } from "moment";
import { ArrayMomentUtil } from "../common";

export interface Project {
    id: number;
    customerId: number;
    customerName?: string;
    name: string;
    start: number[]; // year, month, day
    end: number[]; // year, month, day
    entryHint?: string;
}

export interface UserProject {
    id: number;
    customerId: number;
    customerName?: string;
    customerShortName?: string;
    name: string;
    start: number[]; // year, month, day
    end: number[]; // year, month, day
    entryHint?: string;
    favorite: boolean;
    hidden: boolean;
    default: boolean;
}

export interface ProjectCreateRequest {
    customerId: number;
    name: string;
    start: number[]; // year, month, day
    end: number[]; // year, month, day
    entryHint?: string;
}

export interface ProjectMemberDTO {
    principalId: string;
    displayName: string;
    roles: ProjectMemberRoleDTO[];
}

export interface ProjectMemberRoleDTO {
    id: number;
    name: string;
}

export class ProjectModel {
    constructor(
        id?: number,
        customerId?: number,
        customerName?: string,
        name?: string,
        start?: moment.Moment,
        end?: moment.Moment,
        entryHint?: string
    ) {
        this.id = id;
        this.customerId = customerId;
        this.customerName = customerName;
        this.name = name;
        this.start = start;
        this.end = end;
        this.entryHint = entryHint;
    }

    id: number;
    customerId: number;
    customerName: string;
    name: string;
    start: Moment;
    end: Moment;
    entryHint: string;

    static fromProject(project: Project): ProjectModel {
        return new ProjectModel(
            project.id,
            project.customerId,
            project.customerName,
            project.name,
            ArrayMomentUtil.getMoment(project.start),
            ArrayMomentUtil.getMoment(project.end),
            project.entryHint
        );
    }

    daysUntilEnd() {
        return this.end.diff(moment(), "d");
    }

    hasEnded() {
        return this.daysUntilEnd() < 0;
    }
}

export class UserProjectModel {
    id: number;
    customerId: number;
    customerName: string;
    customerShortName: string;
    name: string;
    start: Moment;
    end: Moment;
    entryHint: string;
    favorite: boolean;
    hidden: boolean;
    default: boolean;
    running: boolean;

    static fromProject(project: UserProject): UserProjectModel {
        const now = moment();
        const start = moment()
            .year(project.start[0])
            .month(project.start[1] - 1)
            .date(project.start[2])
            .startOf("day");
        const end = moment()
            .year(project.end[0])
            .month(project.end[1] - 1)
            .date(project.end[2])
            .startOf("day");
        const isActive = start.isSameOrBefore(now) && end.isSameOrAfter(now);

        return {
            id: project.id,
            customerId: project.customerId,
            customerName: project.customerName,
            customerShortName: project.customerShortName,
            name: project.name,
            start: start,
            end: end,
            entryHint: project.entryHint,
            favorite: project.favorite,
            hidden: project.hidden,
            default: project.default,
            running: isActive,
        };
    }
}
