<mat-card appearance="outlined" id="jump-to">
    <mat-card-content>
            <button id="yearMinus" mat-icon-button (click)="year = year - 1; yearChanged.emit(year)">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <mat-form-field>
                <mat-label>Year</mat-label>
                <input matInput type="number" [(ngModel)]="year" id="year" name="year" (change)="yearChanged.emit(year)" />
            </mat-form-field>
            <button id="yearPlus" mat-icon-button (click)="year = year + 1; yearChanged.emit(year)">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>

            <button id="monthMinus" mat-icon-button (click)="addMonth(-1)">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <mat-form-field>
                <mat-label>Month</mat-label>
                <mat-select
                    [(ngModel)]="currentMonth"
                    id="month"
                    name="month"
                    (selectionChange)="jumpToElement()"
                >
                    <ng-container *ngFor="let month of months; index as i">
                        <mat-option [value]="i + 1">{{ month }}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <button id="monthPlus" mat-icon-button (click)="addMonth(+1)">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
    </mat-card-content>
</mat-card>

<br />

<div id="calendar-container" class="calendar-container">
    <div id="calendar-row-titles">
        <div id="row-title-month" class="month"></div>
        <div id="row-title-day-name-1" class="day-name"></div>
        <div id="row-title-day-name-2" class="day-name"></div>

        <ng-container *ngFor="let row of schedulerRows">
            <div class="name" title="{{ row.information }}">
                {{ row.name }}
            </div>
        </ng-container>
    </div>

    <div id="calendar" class="calendar" (scroll)="changeMonthField()">
        <ng-container
            id="month-titles"
            *ngFor="let monthGrid of monthGrids; index as i"
        >
            <span
                id="{{ i + 1 }}"
                class="month"
                [style.grid-column-start]="monthGrid.gridColumnStart"
                [style.grid-column-end]="monthGrid.gridColumnEnd"
            >
                {{ monthGrid.monthName }}
            </span>
        </ng-container>

        <ng-container
            id="daysOfYear"
            *ngFor="let dayOfYear of daysOfYear; index as dayOfYearIndex"
        >
            <span class="day-name" [style.grid-row]="2">{{
                dayOfYear.format("dd")
            }}</span>
            <span class="day-name" [style.grid-row]="3">{{
                dayOfYear.format("D")
            }}</span>

            <ng-container *ngFor="let r of schedulerRows; index as row">
                <div
                    class="day"
                    [ngStyle]="{
                        'background-color':
                            dayOfYear.month() % 2 === 0 ? 'white' : '#f2f2f2'
                    }"
                    [style.grid-row]="row + 4"
                    [style.grid-column-start]="dayOfYearIndex + 1"
                    [style.grid-column-end]="dayOfYearIndex + 1"
                ></div>
            </ng-container>
        </ng-container>

        <ng-container
            id="entries"
            *ngFor="let r of schedulerRows; index as row"
        >
            <ng-container *ngFor="let content of r.content">
                <section
                    class="entry entry--primary"
                    title="{{ content.title }}"
                    [ngStyle]="{
                        'background-color': content.color
                            ? content.color
                            : '#2196f3',
                        cursor: content.clickable ? 'pointer' : 'default'
                    }"
                    [style.grid-row]="row + 4"
                    [style.grid-column-start]="content.start.dayOfYear()"
                    [style.grid-column-end]="content.end.dayOfYear() + 1"
                    (click)="click(content)"
                >
                    {{ content.title.substring(0, 2).toUpperCase() || "?" }}
                </section>
            </ng-container>
        </ng-container>
    </div>
</div>
