import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomerOverviewComponent } from "./customer-overview/customer-overview.component";
import { CreateCustomerDialogComponent } from "./create-customer-dialog/create-customer-dialog.component";
import { FormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatTableModule } from "@angular/material/table";
import { MatInputModule } from "@angular/material/input";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        MatTableModule,
        MatIconModule,
        MatTooltipModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatDialogModule,
        FlexLayoutModule,
        MatRippleModule,
    ],
    entryComponents: [CreateCustomerDialogComponent],
    declarations: [CustomerOverviewComponent, CreateCustomerDialogComponent],
})
export class CustomerModule {}
