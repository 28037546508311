<form #roleForm="ngForm">
    <h2 mat-dialog-title>
        {{ isEditMode ? "Edit" : "Add" }} Project Role
    </h2>
    <mat-dialog-content>
        <div fxLayout="row" fxLayoutAlign="space-between" *ngIf="roles">
            <mat-form-field fxFlex="1 1 100%" *ngIf="compensatedRoles">
                <mat-label>Role</mat-label>
                <mat-select
                    required
                    id="role"
                    name="role"
                    [disabled]="isEditMode"
                    [(ngModel)]="dialogModel.roleId"
                >
                    <mat-option
                        *ngFor="let role of getFilteredRoles()"
                        [value]="role.id"
                    >
                        {{ role.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <mat-form-field style="width: 100%">
            <mat-label>Compensation</mat-label>
            <input matInput
                required
                id="compensation"
                name="compensation"
                [(ngModel)]="dialogModel.compensation"
            >

        </mat-form-field>

    </mat-dialog-content>

    <mat-dialog-actions>
        <button type="button" mat-stroked-button [mat-dialog-close]="false">
            Cancel
        </button>
        <button
            mat-stroked-button
            style="margin-left: 10px"
            type="submit"
            [disabled]="!roleForm.valid"
            [mat-dialog-close]="dialogModel"
        >
            Save
        </button>
    </mat-dialog-actions>
</form>
