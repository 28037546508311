<div>
    <h1>Absences Overview {{ year }}</h1>
    <div *ngIf="schedulerRows">
        <app-scheduler
            [initialYear]="year"
            [schedulerRows]="schedulerRows"
            (yearChanged)="year = $event; reloadAbsences()"
        ></app-scheduler>
    </div>
</div>
