<div>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <h1>Available Vacation Day Management</h1>
        <button
            id="addDays1"
            mat-stroked-button
            color="primary"
            (click)="openCreateAvailableVacationDaysDialog()"
        >
            Add days
        </button>
    </div>
</div>
<div *ngIf="usersAvailableVacationDays">
    <div id="dayTableContainer" *ngIf="thereAreVacationDays()">
        <ng-container
            *ngFor="
                let usersAvailableVacationDayList of usersAvailableVacationDays
            "
        >
            <div
                *ngIf="
                    usersAvailableVacationDayList.vacationDaysInformation
                        .length > 0
                "
                fxLayout="column"
                class="cs-table"
                style="line-height: 45px"
            >
                <ng-container
                    *ngIf="
                        usersAvailableVacationDayList.vacationDaysInformation
                            .length > 0
                    "
                >
                    <div class="cs-table-row enclosing">
                        <div fxFlex="0 0 300px">User</div>
                        <div fxFlex class="padding-left margin-right">
                            Validity
                        </div>
                        <div fxFlex class="padding-left margin-right">
                            Vacation days
                        </div>
                        <div
                            fxFlex="0 1 120px"
                            class="cgi-cell-right padding-left"
                        >
                            Vacation days left
                        </div>
                    </div>

                    <div
                        class="cs-table-row highlight"
                        matRipple
                        [ngClass]="!isFirst ? 'nodivider' : ''"
                        *ngFor="
                            let item of usersAvailableVacationDayList.vacationDaysInformation;
                            first as isFirst
                        "
                        (click)="
                            openEditAvailableVacationDaysDialog(
                                usersAvailableVacationDayList.userId,
                                item
                            )
                        "
                    >
                        <div fxLayout="row" class="editable">
                            <div
                                fxFlex="0 0 300px"
                                class="user"
                                title="{{
                                    usersAvailableVacationDayList.userId
                                }}"
                            >
                                <ng-container *ngIf="isFirst">{{
                                    usersAvailableVacationDayList.userName
                                }}</ng-container>
                            </div>
                            <div fxFlex>
                                {{ item.validFrom.format("DD.MM.YYYY") }} -
                                {{ item.validUntil.format("DD.MM.YYYY") }}
                            </div>
                            <div fxFlex>
                                {{ item.numberOfDays }}
                            </div>
                            <div
                                fxFlex="0 1 120px"
                                class="cgi-cell-right padding-left"
                            >
                                {{ item.numberOfDaysLeft }}
                            </div>
                        </div>
                    </div>
                    <div class="cs-table-row enclosing">
                        <div fxFlex="0 0 300px">Sum</div>
                        <div fxFlex></div>
                        <div fxFlex>
                            {{
                                UsersAvailableVacationDaysModelUtil.sumDays(
                                    usersAvailableVacationDayList
                                ).toFixed(1)
                            }}
                        </div>
                        <div fxFlex="0 1 120px">
                            {{
                                UsersAvailableVacationDaysModelUtil.sumDaysLeft(
                                    usersAvailableVacationDayList
                                ).toFixed(1)
                            }}
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>

    <div
        id="noDays"
        *ngIf="!thereAreVacationDays()"
        style="margin-bottom: 24px"
    >
        <mat-card appearance="outlined">No available vacation days found</mat-card>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="end">
    <div fxLayout="row" fxLayoutAlign="end center">
        <div>
            <button
                id="addDays2"
                mat-stroked-button
                color="primary"
                style="margin-left: 10px"
                (click)="openCreateAvailableVacationDaysDialog()"
            >
                Add days
            </button>
        </div>
    </div>
</div>
