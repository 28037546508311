<h1>Customers</h1>

<div class="cs-table">
    <div class="cs-table-row enclosing">
        <div fxFlex>Name</div>
        <div fxFlex>Short name</div>
    </div>

    <div
        class="cs-table-row action"
        matRipple
        [ngClass]="!customer.enabled ? 'disabled' : ''"
        *ngFor="let customer of customers"
        (click)="openCreateCustomerModal(customer)"
    >
        <div fxFlex>{{ customer.name }}</div>
        <div fxFlex>{{ customer.shortName }}</div>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="end">
    <button
        mat-stroked-button
        color="primary"
        (click)="openCreateCustomerModal(null)"
    >
        Create customer
    </button>
</div>
