import { Moment } from "moment";
import {
    AbsenceDaySegment,
    AbsenceDTO,
    AbsenceModel,
    AbsenceType,
} from "./absence";
import { ArrayMomentUtil } from "../common";

export enum VacationState {
    OPEN = "Open",
    APPROVED = "Approved",
    DECLINED = "Declined",
    WIP = "In progress",
}

export interface AvailableVacationDaysDTO {
    numberOfDays: number;
    validFrom: number[];
    validUntil: number[];
}

export interface UsersAvailableVacationDaysDTO {
    userId: string;
    userName: string;
    vacationDaysInformation: VacationDaysInformationDTO[];
}

export interface VacationDaysInformationDTO {
    ids: number[];
    numberOfDays: number;
    numberOfDaysLeft: number;
    validFrom: number[];
    validUntil: number[];
}

export class UsersAvailableVacationDaysModel {
    userId: string;
    userName: string;
    vacationDaysInformation: VacationDaysInformationModel[];

    constructor(
        userId: string,
        userName: string,
        availableVacationDays: VacationDaysInformationModel[]
    ) {
        this.userId = userId;
        this.userName = userName;
        this.vacationDaysInformation = availableVacationDays;
    }

    static fromUsersAvailableVacationDaysDTO(
        usersAvailableVacationDaysDTO: UsersAvailableVacationDaysDTO
    ): UsersAvailableVacationDaysModel {
        return new UsersAvailableVacationDaysModel(
            usersAvailableVacationDaysDTO.userId,
            usersAvailableVacationDaysDTO.userName,
            usersAvailableVacationDaysDTO.vacationDaysInformation.map((value) =>
                VacationDaysInformationModel.fromVacationDaysInformationDTO(
                    value
                )
            )
        );
    }
}

export class UsersAvailableVacationDaysModelUtil {
    static sumDaysLeft(
        usersAvailableVacationDaysModel: UsersAvailableVacationDaysModel
    ): number {
        return usersAvailableVacationDaysModel.vacationDaysInformation
            .map(
                (vacationDaysInformation) =>
                    vacationDaysInformation.numberOfDaysLeft
            )
            .reduce((p, c) => p + c, 0);
    }

    static sumDays(
        usersAvailableVacationDaysModel: UsersAvailableVacationDaysModel
    ): number {
        return usersAvailableVacationDaysModel.vacationDaysInformation
            .map(
                (vacationDaysInformation) =>
                    vacationDaysInformation.numberOfDays
            )
            .reduce((p, c) => p + c, 0);
    }
}

export class VacationDaysInformationModel {
    ids: number[];
    numberOfDays: number;
    numberOfDaysLeft: number;
    validFrom: Moment;
    validUntil: Moment;

    constructor(
        ids: number[],
        numberOfDays: number,
        numberOfDaysLeft: number,
        validFrom: Moment,
        validUntil: Moment
    ) {
        this.ids = ids;
        this.numberOfDays = numberOfDays;
        this.numberOfDaysLeft = numberOfDaysLeft;
        this.validFrom = validFrom;
        this.validUntil = validUntil;
    }

    static fromVacationDaysInformationDTO(
        vacationDaysInformationDTO: VacationDaysInformationDTO
    ): VacationDaysInformationModel {
        return new VacationDaysInformationModel(
            vacationDaysInformationDTO.ids,
            vacationDaysInformationDTO.numberOfDays,
            vacationDaysInformationDTO.numberOfDaysLeft,
            ArrayMomentUtil.getMoment(vacationDaysInformationDTO.validFrom),
            ArrayMomentUtil.getMoment(vacationDaysInformationDTO.validUntil)
        );
    }
}

export class SaveAvailableVacationDaysDTO {
    ids?: number[];
    userId: string;
    validFrom: number[];
    validUntil: number[];
    numberOfVacationDays: number;

    constructor(
        ids: number[],
        userId: string,
        startDate: number[],
        endDate: number[],
        numberOfVacationDays: number
    ) {
        this.ids = ids;
        this.userId = userId;
        this.validFrom = startDate;
        this.validUntil = endDate;
        this.numberOfVacationDays = numberOfVacationDays;
    }
}

export class VacationDTO {
    id: number;
    userId: string;
    userName: string;
    state: string;
    vacationDays: VacationDayDTO[];
}

export class VacationDayDTO {
    date: number[];
    daySegment: string;

    constructor(date: number[], daySegment: string) {
        this.date = date;
        this.daySegment = daySegment;
    }
}

export class VacationModel {
    id: number;
    userId: string;
    userName: string;
    vacationDays: VacationDayModel[];
    state: VacationState;

    constructor(
        id: number,
        userId: string,
        userName: string,
        vacationDays: VacationDayModel[],
        state: VacationState
    ) {
        this.id = id;
        this.userId = userId;
        this.userName = userName;
        this.vacationDays = vacationDays;
        this.state = state;
    }

    static fromVacationDTO(vacationDTO: VacationDTO): VacationModel {
        return new VacationModel(
            vacationDTO.id,
            vacationDTO.userId,
            vacationDTO.userName,
            vacationDTO.vacationDays.map((value) =>
                VacationDayModel.fromVacationDTO(value)
            ),
            VacationState[vacationDTO.state]
        );
    }
}

export class VacationDayModel {
    date: Moment;
    daySegment: string;

    constructor(date: Moment, daySegment: string) {
        this.date = date;
        this.daySegment = daySegment;
    }

    static fromVacationDTO(vacationDayDTO: VacationDayDTO): VacationDayModel {
        return new VacationDayModel(
            ArrayMomentUtil.getMoment(vacationDayDTO.date),
            vacationDayDTO.daySegment
        );
    }
}

export class VacationOverviewDTO implements AbsenceDTO {
    id: number;
    userId: string;
    userName: string;
    startDate: number[];
    startDateDaySegment: string;
    endDate: number[];
    endDateDaySegment?: string;
    type: string;
    state: string;
}

export class VacationOverviewModel extends AbsenceModel {
    state: VacationState;

    constructor(
        id: number,
        userId: string,
        userName: string,
        startDate: Moment,
        startDateDaySegment: string,
        endDate: Moment,
        endDateDaySegment: string,
        type: AbsenceType,
        state: VacationState
    ) {
        super(
            id,
            startDate,
            AbsenceDaySegment[startDateDaySegment],
            endDate,
            AbsenceDaySegment[endDateDaySegment],
            userId,
            userName,
            type
        );
        this.state = state;
    }

    static fromVacationOverviewDTO(
        vacationOverviewDTO: VacationOverviewDTO
    ): VacationOverviewModel {
        return new VacationOverviewModel(
            vacationOverviewDTO.id,
            vacationOverviewDTO.userId,
            vacationOverviewDTO.userName,
            ArrayMomentUtil.getMoment(vacationOverviewDTO.startDate),
            vacationOverviewDTO.startDateDaySegment,
            ArrayMomentUtil.getMoment(vacationOverviewDTO.endDate),
            vacationOverviewDTO.endDateDaySegment,
            AbsenceType[vacationOverviewDTO.type],
            VacationState[vacationOverviewDTO.state]
        );
    }
}
