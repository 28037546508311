import { Component, Inject, OnInit } from "@angular/core";
import { VacationService } from "../vacation.service";
import { VacationModel, VacationState } from "../vacation";
import { CalendarItem } from "../../calendar/calendar.component";
import * as moment from "moment";
import { Moment } from "moment";
import { Mode } from "../../mode";
import { InfoSnackbarUtil } from "../../common/info-snackbar/info-snackbar.component";
import { EnumUtil } from "../../common";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-verify-vacation-dialog",
    templateUrl: "./verify-vacation-dialog.component.html",
    styleUrls: ["./verify-vacation-dialog.component.sass"],
})
export class VerifyVacationDialogComponent implements OnInit {
    VacationState = VacationState;

    Mode = Mode;
    mode: Mode;

    vacationModel: VacationModel;

    private selectedMonth: number;
    calendarItems: CalendarItem[];
    minViewport: Moment;
    maxViewport: Moment;
    focusDay: Moment;

    private dialogRef: MatDialogRef<any>;

    constructor(
        dialogRef: MatDialogRef<any>,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public vacationId: number,
        private vacationService: VacationService
    ) {
        this.dialogRef = dialogRef;
        this.selectedMonth = 0;
    }

    ngOnInit() {
        this.vacationService
            .readVacationById(this.vacationId)
            .subscribe((vacationDTO) => {
                this.mode =
                    VacationState[vacationDTO.state] === VacationState.OPEN ||
                    VacationState[vacationDTO.state] === VacationState.WIP
                        ? Mode.EDIT
                        : Mode.DISPLAY;

                this.vacationModel = VacationModel.fromVacationDTO(vacationDTO);

                this.focusDay = moment
                    .min(
                        this.vacationModel.vacationDays.map(
                            (vacationDay) => vacationDay.date
                        )
                    )
                    .clone();
                this.minViewport = moment
                    .min(
                        this.vacationModel.vacationDays.map(
                            (vacationDay) => vacationDay.date
                        )
                    )
                    .clone()
                    .startOf("month");
                this.maxViewport = moment
                    .max(
                        this.vacationModel.vacationDays.map(
                            (vacationDay) => vacationDay.date
                        )
                    )
                    .clone()
                    .endOf("month");

                const items: CalendarItem[] = [];
                this.vacationModel.vacationDays.map((vacationDay) =>
                    items.push({
                        moment: vacationDay.date,
                        daySegment: vacationDay.daySegment,
                        clickable: false,
                    })
                );
                this.calendarItems = items;
            });
    }

    verifyVacation(vacationState: string): void {
        this.vacationService
            .verifyVacation(
                this.vacationId,
                EnumUtil.getKeyByValue(VacationState, vacationState)
            )
            .subscribe({
                complete: () => this.dialogRef.close(),
                error: (err) =>
                    InfoSnackbarUtil.getInfoSnackbarComponentMatSnackBarRef(
                        this.snackBar,
                        err
                    ),
            });
    }
}
