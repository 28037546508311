import { Component, Inject, OnInit } from "@angular/core";
import {
    TimesheetEntryCreateDTO,
    TimesheetEntryDTO,
    TimesheetEntryTableItem,
} from "../timesheet-entry";
import { Mode } from "../../mode";
import { ProjectService } from "../../projects/project.service";
import { UserProjectModel } from "../../projects/project";
import { TimetrackingService } from "../timetracking.service";
import * as moment from "moment";
import { Moment } from "moment";
import { ProjectRoleDTO } from "../../projects/project-role";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-create-timesheet-entry-dialog",
    templateUrl: "./create-timesheet-entry-dialog.component.html",
    styleUrls: ["./create-timesheet-entry-dialog.component.sass"],
})
export class CreateTimesheetEntryDialogComponent implements OnInit {
    Mode = Mode;
    mode: Mode;
    dialogModel = new TimesheetDialogModel();
    availableProjects: UserProjectModel[] = [];
    availableProjectRoles: ProjectRoleDTO[] = [];

    constructor(
        private projectService: ProjectService,
        private timetrackingService: TimetrackingService,
        @Inject(MAT_DIALOG_DATA)
        public editEntry: { element: TimesheetEntryTableItem; edit: boolean },
        private dialogRef: MatDialogRef<TimesheetEntryDTO>
    ) {}

    public static hours(minutes: number): number {
        return Math.floor(minutes / 60);
    }

    public static minutes(minutes: number): number {
        return minutes % 60;
    }

    ngOnInit() {
        this.mode = this.editEntry.edit ? Mode.EDIT : Mode.CREATE;
        if (this.editEntry.element) {
            this.dialogModel.id = this.editEntry.element.id;
            this.dialogModel.hours = CreateTimesheetEntryDialogComponent.hours(
                this.editEntry.element.duration
            );
            this.dialogModel.minutes =
                CreateTimesheetEntryDialogComponent.minutes(
                    this.editEntry.element.duration
                );
            this.dialogModel.description = this.editEntry.element.description;
            this.dialogModel.date = this.editEntry.element.date.clone();
        } else {
            this.dialogModel.date = moment();
            this.dialogModel.hours = 0;
            this.dialogModel.minutes = 0;
        }

        this.updateAvailableProjects();
    }

    updateAvailableProjects() {
        this.projectService
            .readMyProjects(this.dialogModel.date)
            .subscribe((projects) => {
                this.availableProjects = projects
                    .filter((p) => !p.hidden)
                    .sort((a, b) =>
                        a.customerName + a.name > b.customerName + b.name
                            ? 1
                            : -1
                    )
                    .sort((a, b) => {
                        if (a.favorite === b.favorite) {
                            return 0;
                        } else {
                            return !a.favorite ? 1 : -1;
                        }
                    })
                    .map((project) => UserProjectModel.fromProject(project));

                if (this.dialogModel.projectId) {
                    // make sure selected id is still available
                    this.dialogModel.projectId = this.findAvailableProject(
                        this.dialogModel.projectId
                    );
                }

                // wenn leer, schlau befüllen
                if (!this.dialogModel.projectId) {
                    this.dialogModel.projectRoleId = null;

                    if (this.editEntry.element) {
                        this.dialogModel.projectId = this.findAvailableProject(
                            this.editEntry.element.projectId
                        );
                    } else {
                        if (this.availableProjects.length === 1) {
                            this.dialogModel.projectId =
                                this.availableProjects[0].id;
                        } else {
                            const defaultProject = this.availableProjects.find(
                                (p) => p.default
                            );
                            if (defaultProject) {
                                this.dialogModel.projectId = defaultProject.id;
                            }
                        }
                    }
                }

                this.updateAvailableRoles();
            });
    }

    private findAvailableProject(projectId: number): number | null {
        const availableProject = this.availableProjects.find(
            (p) => p.id === projectId
        );
        return availableProject ? projectId : null;
    }

    updateAvailableRoles() {
        if (this.dialogModel.projectId) {
            this.projectService
                .readMyProjectRolesByProject(this.dialogModel.projectId)
                .subscribe((availableRoles) => {
                    this.availableProjectRoles = availableRoles;

                    if (this.dialogModel.projectRoleId) {
                        // make sure selected id is still available
                        this.dialogModel.projectRoleId = this.findAvailableRole(
                            this.dialogModel.projectRoleId
                        );
                    }

                    if (this.editEntry.element) {
                        this.dialogModel.projectRoleId = this.findAvailableRole(
                            this.editEntry.element.roleId
                        );
                    } else if (this.availableProjectRoles.length === 1) {
                        this.dialogModel.projectRoleId =
                            this.availableProjectRoles[0].id;
                    }
                });
        } else {
            this.dialogModel.projectRoleId = null;
            this.availableProjectRoles = [];
        }
    }

    findAvailableRole(roleId: number): number | null {
        const selectedRole = this.availableProjectRoles.find(
            (p) => p.id === roleId
        );
        return selectedRole ? roleId : null;
    }

    deleteEntry() {
        this.timetrackingService
            .deleteTimesheetEntry(this.dialogModel.id)
            .subscribe({
                complete: () => this.dialogRef.close(true),
            });
    }

    saveEntry() {
        const createDTO: TimesheetEntryCreateDTO = {
            projectId: this.dialogModel.projectId,
            roleId: this.dialogModel.projectRoleId,
            year: this.dialogModel.date.year(),
            month: this.dialogModel.date.month() + 1,
            day: this.dialogModel.date.date(),
            duration: this.dialogModel.hours * 60 + this.dialogModel.minutes,
            description: this.dialogModel.description,
        };

        if (this.mode === Mode.CREATE) {
            this.dialogModel.id = null;
            this.timetrackingService.saveTimesheetEntry(createDTO).subscribe({
                complete: () => this.dialogRef.close(true),
            });
        } else {
            this.timetrackingService
                .updateTimesheetEntry(this.dialogModel.id, createDTO)
                .subscribe({
                    complete: () => this.dialogRef.close(true),
                });
        }
    }

    addDays(days: number) {
        this.dialogModel.date = moment(this.dialogModel.date.add(days, "days"));
        this.updateAvailableProjects();
    }
}

export class TimesheetDialogModel {
    public id: number;

    public projectId: number;
    public projectRoleId: number;
    public date: Moment;

    public hours: number;
    public minutes: number;
    public description: string;
}
