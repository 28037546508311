<h1>Users</h1>

<div class="cs-table">
    <div class="cs-table-row enclosing">
        <div fxFlex>ID</div>
        <div fxFlex>Name</div>
        <div fxFlex>Region</div>
        <div fxFlex="0 1 50px" class="cgi-cell-right">Roles #</div>
    </div>

    <div
        class="cs-table-row action"
        matRipple
        *ngFor="let user of allUsers"
        [ngClass]="!user.enabled ? 'disabled' : ''"
        (click)="openUserModal(user)"
    >
        <div fxFlex>{{ user.principalId }}</div>
        <div fxFlex>{{ user.displayName }}</div>
        <div fxFlex>{{ user.region.name }}</div>
        <div fxFlex="0 1 50px" class="cgi-cell-right">
            {{ user.roles.length }}
        </div>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="end">
    <button mat-stroked-button color="primary" (click)="openUserModal(null)">
        Create user
    </button>
</div>
