import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from "@angular/router";
import { Observable, ReplaySubject } from "rxjs";
import { Injectable } from "@angular/core";
import { UserService } from "./users/user.service";
import { MsalService } from "@azure/msal-angular";

export abstract class BaseGuard implements CanActivate {
    public canActivateRs: ReplaySubject<boolean> = new ReplaySubject<boolean>(
        1
    );

    protected constructor(private userService: UserService, role: string) {
        this.userService.me.subscribe((user) =>
            this.canActivateRs.next(user && user.roles.indexOf(role) !== -1)
        );
    }

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivateRs;
    }
}

@Injectable()
export class ProjectAdminGuard extends BaseGuard {
    constructor(userService: UserService) {
        super(userService, "PROJECT_ADMIN");
    }
}

@Injectable()
export class CustomerAdminGuard extends BaseGuard {
    constructor(userService: UserService) {
        super(userService, "CUSTOMER_ADMIN");
    }
}

@Injectable()
export class UserAdminGuard extends BaseGuard {
    constructor(userService: UserService) {
        super(userService, "USER_ADMIN");
    }
}

@Injectable()
export class ReportingGuard extends BaseGuard {
    constructor(userService: UserService) {
        super(userService, "REPORTING");
    }
}

@Injectable()
export class HrGuard extends BaseGuard {
    constructor(userService: UserService) {
        super(userService, "HR");
    }
}

@Injectable()
export class LoggedInGuard implements CanActivate {
    constructor(private msalService: MsalService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.msalService.instance.getAllAccounts().length > 0;
    }
}
