<h1>Project Roles</h1>

<div fxLayout="column" class="cs-table">
    <div fxLayout="row" class="cs-table-row enclosing">
        <div fxFlex="0 1 250px">Role</div>
        <div fxFlex>Description</div>
        <div fxFlex>Invoice-Reference</div>
    </div>

    <div
        fxLayout="row"
        *ngFor="let projectRole of projectRoles | async"
        class="cs-table-row action"
        fxLayoutAlign="start center"
        matRipple
        (click)="openProjectRoleDialog(projectRole)"
    >
        <div fxFlex="0 1 250px">{{ projectRole.name }}</div>
        <div fxFlex>{{ projectRole.description }}</div>
        <div fxFlex>{{ projectRole.invoiceReference }}</div>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="end">
    <button
        mat-stroked-button
        color="primary"
        (click)="openProjectRoleDialog(null)"
    >
        Create Role
    </button>
</div>
