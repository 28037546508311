import * as moment from "moment";
import { Moment } from "moment";

export interface Page<T> {
    totalElements: number;
    content: T[];
}

export class TimeRange {
    constructor(public start: Moment, public end: Moment) {}
}

export class TimeRangeUtil {
    static getHalfYear(aMoment: Moment): TimeRange {
        const { startMoment, endMoment } = {
            startMoment: aMoment.clone(),
            endMoment: aMoment.clone(),
        };

        if (aMoment.quarter() === 1 || aMoment.quarter() === 2) {
            return new TimeRange(
                startMoment.startOf("year"),
                endMoment.quarter(2).endOf("quarter")
            );
        } else {
            return new TimeRange(
                startMoment.quarter(3).startOf("quarter"),
                endMoment.endOf("year")
            );
        }
    }
    static isSameDay(start: Moment, end: Moment): boolean {
        if (start !== null && end !== null) {
            return (
                start.year() === end.year() &&
                start.month() === end.month() &&
                start.date() === end.date()
            );
        }
        return false;
    }
}

export class ArrayMomentUtil {
    static getMoment(yearMonthDay: number[]): Moment {
        if (yearMonthDay !== null) {
            const [year, month, day] = yearMonthDay;
            return moment()
                .year(year)
                .month(month - 1)
                .date(day)
                .startOf("day");
        }
        return null;
    }

    static getArray(aMoment: Moment): number[] {
        const year = aMoment.clone().year();
        const month = aMoment.clone().month() + 1;
        const day = aMoment.clone().date();
        return [year, month, day];
    }
}

export class MonthYearModel {
    constructor(public year: number, public month: number) {}
}

export class EnumUtil {
    static getKeyByValue(enumType: any, value: any) {
        return Object.keys(enumType).filter(
            (key) => enumType[key] === value
        )[0];
    }
}
