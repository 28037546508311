import { Injectable } from "@angular/core";
import { Observable, of, interval } from "rxjs";
import { environment } from "../environments/environment";
import { HttpClient } from "@angular/common/http";
import { catchError, map, switchMap, tap } from "rxjs/operators";

@Injectable()
export class BackendService {
    public isAvailable: boolean;

    constructor(private http: HttpClient) {
        this.checkBackendHealth();
    }

    private checkBackendHealth(): void {
        interval(2000)
            .pipe(
                switchMap(() =>
                    this.http.get<HealthResponse>(
                        environment.backendUrl + "/actuator/health"
                    )
                ),
                map((response) => response.status === "UP"),
                tap((value) => (this.isAvailable = value)),
                catchError(() => of((this.isAvailable = false)))
            )
            .subscribe();
    }

    getUserPhoto(): Promise<Blob> {
        return new Promise((resolve, reject) => {
            this.http
                .get(
                    "https://graph.microsoft.com/v1.0/me/photos/96x96/$value",
                    {
                        responseType: "blob",
                    }
                )
                .subscribe(
                    (img) => {
                        resolve(img);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }
}

export interface HealthResponse {
    status: string;
}
