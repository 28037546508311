import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CreateHolidayDialogComponent } from "../create-holiday-dialog/create-holiday-dialog.component";
import {HolidayService} from '../holiday.service';
import {Holiday, HolidayDto, HolidaySaveModel, HolidayType} from '../holiday';
import * as moment from 'moment';
import {Region} from '../../regions/region';
import {RegionService} from '../../regions/region.service';

@Component({
    selector: "app-holiday-overview",
    templateUrl: "./holiday-overview.component.html",
    styleUrls: ["./holiday-overview.component.sass"],
})
export class HolidayOverviewComponent implements OnInit {
    public filteredHolidays: Holiday[];
    public availableRegions: Region[];
    public region: Region;
    public year: number;

    constructor(private holidayService: HolidayService, private regionService: RegionService, private dialog: MatDialog) {}

    ngOnInit() {
        this.year = moment().year();
        this.regionService.readRegions().subscribe(((next) => {
            this.availableRegions = next;
            this.region = this.availableRegions[0];
            this.reloadHolidays();
        }));
    }

    newHolidayWithRegionOnly(region: Region) {
        return new Holiday(null, null, null, null, region);
    }

    importPublicHolidays() {
        this.holidayService.importPublicHolidays(this.year, this.region, async (apiFeiertag) => {
            if (this.filteredHolidays.some(holiday => holiday.date.isSame(moment(apiFeiertag.date), 'day'))) {
                console.log(`Skipping holiday ${apiFeiertag.fname} on ${apiFeiertag.date} because it already exists`);
            } else {
                await this.holidayService.saveHoliday(HolidaySaveModel.fromApiFeiertagAndRegion(apiFeiertag, this.region.id));
            }
        }, () => this.reloadHolidays());
    }

    deleteAllVisibleHolidays() {
        this.holidayService.deleteAllHolidays(this.year, this.region.id).subscribe(() => this.reloadHolidays());
    }

    openHolidayModal(holiday: Holiday) {
        this.dialog
            .open(CreateHolidayDialogComponent, {
                data: holiday,
                maxWidth: "450px",
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.reloadHolidays();
                }
            });
    }

    reloadHolidays() {
        if (this.year && this.region) {
            this.holidayService.readHolidays(moment([this.year, 0, 1]), moment([this.year, 11, 31]), this.region.id).subscribe((next) => this.filteredHolidays = next.map(holidayDto => HolidayDto.toHoliday(holidayDto)));
        }
    }

    protected readonly HolidayType = HolidayType;
}
