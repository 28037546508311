import { Component, OnInit } from "@angular/core";
import { Customer } from "../customer";
import { CustomerService } from "../customer.service";
import { MatDialog } from "@angular/material/dialog";
import { CreateCustomerDialogComponent } from "../create-customer-dialog/create-customer-dialog.component";

@Component({
    selector: "app-customer-overview",
    templateUrl: "./customer-overview.component.html",
    styleUrls: ["./customer-overview.component.sass"],
})
export class CustomerOverviewComponent implements OnInit {
    public customers: Customer[] = [];

    constructor(
        private customerService: CustomerService,
        private dialog: MatDialog
    ) {}

    ngOnInit() {
        this.reloadCustomers();
    }

    private reloadCustomers() {
        this.customerService.readCustomers(0, 20).subscribe({
            next: (value) =>
                (this.customers = value.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                )),
        });
    }

    openCreateCustomerModal(customer: Customer) {
        this.dialog
            .open(CreateCustomerDialogComponent, {
                data: customer,
                disableClose: true,
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.reloadCustomers();
                }
            });
    }
}
