<form #regionForm="ngForm" (ngSubmit)="saveRegion()">
    <h2 mat-dialog-title>
        {{ mode === Mode.CREATE ? "Create" : "Edit" }} Region
    </h2>
    <mat-dialog-content>
        <mat-form-field style="width: 100%">
            <mat-label>Name</mat-label>
            <input
                matInput
                [(ngModel)]="model.name"
                id="name"
                name="name"
                maxlength="64"
                type="text"
                required
            />
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <mat-label>Federal State</mat-label>
            <mat-select
                [multiple]="false"
                [(ngModel)]="model.code"
                id="code"
                name="code"
                required="false"
            >
                <mat-option value="">-</mat-option>
                <mat-option
                    *ngFor="let availableStates of GERMAN_FEDERAL_STATES"
                    class="left circle"
                    [value]="availableStates.id">
                    {{ availableStates.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button (click)="deleteRegion()" style="color: red" *ngIf="mode == Mode.EDIT">
            Delete
        </button>
        <button mat-stroked-button type="submit" [disabled]="!regionForm.valid">
            Save
        </button>
        <button
            type="button"
            mat-stroked-button
            style="margin-left: 10px"
            [mat-dialog-close]="false"
        >
            Cancel
        </button>
    </mat-dialog-actions>
</form>
