<h1>
    <button mat-button routerLink="/manage/projects" onclick="history.back()">
        <mat-icon>keyboard_backspace</mat-icon>
    </button>
    {{ (project | async)?.name }}
</h1>

<div fxLayout="column" class="cs-table">
    <div fxLayout="row" class="cs-table-row enclosing">
        <div fxFlex="1 1">Member name</div>
        <div fxFlex="1 0">Roles</div>
        <div fxFlex="0 0 80px" fxFlexOffset="auto"></div>
    </div>

    <div
        fxLayout="row"
        *ngFor="let projectMemberDetail of projectMemberDetails | async"
        class="cs-table-row highlight"
        fxLayoutAlign="start center"
    >
        <div fxFlex="1 1">
            {{ projectMemberDetail.displayName }}
        </div>

        <div fxFlex="1 0">
            <ul class="taglist">
                <li *ngFor="let role of projectMemberDetail.roles">
                    {{ role.name }}
                </li>
            </ul>
        </div>

        <div fxFlex="0 0 100px" fxFlexOffset="auto">
            <button
                mat-icon-button
                (click)="openEditMemberModal(projectMemberDetail)"
                matTooltip="Edit member"
            >
                <mat-icon>edit</mat-icon>
            </button>
            <button
                mat-icon-button
                (click)="removeFromProject(projectMemberDetail.principalId)"
                matTooltip="Remove member"
            >
                <mat-icon color="warn">delete</mat-icon>
            </button>
        </div>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="end">
    <button mat-stroked-button (click)="openEditMemberModal(null)">
        Add member
    </button>
</div>
