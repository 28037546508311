import * as moment from "moment";
import { Moment } from "moment";
import { ArrayMomentUtil } from "../common";
import { UserInfo } from "../users/userDetails";

export interface AbsenceDTO {
    id: number;
    userId: string;
    userName: string;
    startDate: number[];
    startDateDaySegment: string;
    endDate: number[];
    endDateDaySegment?: string;
    type: string;
}

export interface AbsenceRequest {
    userId: string;
    startDate: number[];
    startDateDaySegment: string;
    endDate: number[];
    endDateDaySegment?: string;
    type: string;
}

export class AbsenceModel {
    id: number;
    start: Moment;
    startDateDaySegment: AbsenceDaySegment;
    end: Moment;
    endDateDaySegment?: AbsenceDaySegment;
    user: UserInfo;
    type: AbsenceType;

    constructor(
        id?: number,
        start?: moment.Moment,
        startDateDaySegment?: AbsenceDaySegment,
        end?: moment.Moment,
        endDateDaySegment?: AbsenceDaySegment,
        principalId?: string,
        displayName?: string,
        type?: AbsenceType
    ) {
        this.id = id;
        this.start = start;
        this.startDateDaySegment = startDateDaySegment;
        this.end = end;
        this.endDateDaySegment = endDateDaySegment;
        this.user = { principalId: principalId, displayName: displayName };
        this.type = type;
    }

    static fromAbsenceDTO(absenceDTO: AbsenceDTO) {
        return new AbsenceModel(
            absenceDTO.id,
            ArrayMomentUtil.getMoment(absenceDTO.startDate),
            AbsenceDaySegment[absenceDTO.startDateDaySegment],
            ArrayMomentUtil.getMoment(absenceDTO.endDate),
            AbsenceDaySegment[absenceDTO.endDateDaySegment],
            absenceDTO.userId,
            absenceDTO.userName,
            AbsenceType[absenceDTO.type]
        );
    }

    isInPast() {
        return this.end.isBefore(moment());
    }
}

export enum AbsenceType {
    VACATION = "Vacation",
    ILLNESS = "Illness",
    BUSINESSTRIP = "Businesstrip",
    UNDEFINED = "?",
}

export enum AbsenceDaySegment {
    WHOLE_DAY = "Whole day",
    FIRST_HALF = "First half",
    SECOND_HALF = "Second half",
}
