import {Component, Inject} from '@angular/core';
import {Mode} from '../../mode';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RegionService} from '../region.service';
import {Region} from '../region';
import {GERMAN_FEDERAL_STATES} from '../federalStates';

@Component({
    selector: 'app-create-region-dialog',
    templateUrl: './create-region-dialog.component.html',
    styleUrls: ['./create-region-dialog.component.sass'],
})
export class CreateRegionDialogComponent {
    public model: Region;
    public mode: Mode;

    public Mode = Mode; // For template

    constructor(private regionService: RegionService, @Inject(MAT_DIALOG_DATA) public region: Region, private dialogRef: MatDialogRef<Region>) {
        if (region) {
            this.model = region;
            this.mode = Mode.EDIT;
        } else {
            this.model = new Region(undefined, undefined, undefined);
            this.mode = Mode.CREATE;
        }
    }

    saveRegion() {
        if (this.model.code === '') {
            this.model.code = null;
        }

        this.regionService.saveRegion(this.model);
        this.dialogRef.close(true);
    }

    deleteRegion() {
        this.regionService
            .deleteRegion(this.model)
            .subscribe({complete: () => this.dialogRef.close(true)});
    }

    protected readonly GERMAN_FEDERAL_STATES = GERMAN_FEDERAL_STATES;
}
