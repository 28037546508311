import {Region} from '../regions/region';

export interface UserInfo {
    principalId: string;
    displayName: string;
}

export interface UserDetails extends UserInfo {
    roles: string[];
    enabled: boolean;
    region: Region;
}

export class UserSaveModel {
    principalId: string;
    displayName: string;
    roles: string[];
    enabled: boolean;
    regionId: number;

    static fromUser(user: UserDetails): UserSaveModel {
        return {
            principalId: user.principalId,
            displayName: user.displayName,
            roles: user.roles,
            enabled: user.enabled,
            regionId: user.region.id
        };
    }
}

export class UserDeleteModel {
    principalId: string;
    displayName: string;

    static fromUser(user: UserDetails): UserDeleteModel {
        return {
            principalId: user.principalId,
            displayName: user.displayName,
        };
    }
}
