import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CreateRegionDialogComponent} from '../create-region-dialog/create-region-dialog.component';
import {RegionService} from '../region.service';
import {Region} from '../region';
import {GERMAN_FEDERAL_STATES} from '../federalStates';

@Component({
    selector: 'app-region-overview',
    templateUrl: './region-overview.component.html',
    styleUrls: ['./region-overview.component.sass'],
})
export class RegionOverviewComponent implements OnInit {
    public allRegions: Region[];

    constructor(private regionService: RegionService, private dialog: MatDialog) {
    }

    ngOnInit() {
        this.reloadAllRegions();
    }

    openRegionModal(region: Region) {
        this.dialog
            .open(CreateRegionDialogComponent, {
                data: new Region(region?.id, region?.name, region?.code),
                maxWidth: '450px',
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.reloadAllRegions();
                }
            });
    }

    private reloadAllRegions() {
        this.regionService.readRegions().subscribe((next) => this.allRegions = next);
    }

    getName(federalStateCode: string) {
        let matchingFederalState = GERMAN_FEDERAL_STATES.find(value => value.id === federalStateCode);
        return matchingFederalState ? matchingFederalState.name : '-';
    }
}
