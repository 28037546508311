import { Injectable } from "@angular/core";
import { TimesheetEntryCreateDTO, TimesheetEntryDTO } from "./timesheet-entry";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class TimetrackingService {
    constructor(private http: HttpClient) {}

    readTimesheetEntries(): Observable<TimesheetEntryDTO[]> {
        return this.http.get<TimesheetEntryDTO[]>(
            environment.backendUrl + "/api/entries"
        );
    }

    readTimesheetFilteredEntries(
        timeSheetFilterParams: TimeSheetFilterParams
    ): Observable<TimesheetEntryDTO[]> {
        const params = {
            startDay: timeSheetFilterParams.startDay.toString(),
            startMonth: timeSheetFilterParams.startMonth.toString(),
            startYear: timeSheetFilterParams.startYear.toString(),

            endDay: timeSheetFilterParams.endDay.toString(),
            endMonth: timeSheetFilterParams.endMonth.toString(),
            endYear: timeSheetFilterParams.endYear.toString(),
            projectIds: timeSheetFilterParams.projectIds.map((value) =>
                value.toString()
            ),
            roleIds: timeSheetFilterParams.roleIds.map((value) =>
                value.toString()
            ),
        };

        return this.http.get<TimesheetEntryDTO[]>(
            environment.backendUrl + `/api/entries`,
            { params: params }
        );
    }

    saveTimesheetEntry(
        createEntryRequest: TimesheetEntryCreateDTO
    ): Observable<TimesheetEntryDTO> {
        return this.http.post<TimesheetEntryDTO>(
            environment.backendUrl + "/api/entries",
            createEntryRequest
        );
    }

    updateTimesheetEntry(
        id: number,
        createEntryRequest: TimesheetEntryCreateDTO
    ): Observable<TimesheetEntryDTO> {
        return this.http.post<TimesheetEntryDTO>(
            environment.backendUrl + `/api/entries/${id}`,
            createEntryRequest
        );
    }

    deleteTimesheetEntry(id: number): Observable<void> {
        return this.http.delete<void>(
            environment.backendUrl + `/api/entries/${id}`
        );
    }

    existsTimesheetEntry(
        projectId: number,
        year: number,
        month: number,
        day: number
    ): Observable<boolean> {
        return this.http.get<boolean>(
            environment.backendUrl +
                `/api/entries/${projectId}/${year}/${month}/${day}`
        );
    }
}

export class TimeSheetFilterParams {
    startDay: number;
    startMonth: number;
    startYear: number;
    endDay: number;
    endMonth: number;
    endYear: number;
    projectIds: Number[] = [];
    roleIds: Number[] = [];
}
