<h1>Regions</h1>

<div class="cs-table">
    <div class="cs-table-row enclosing">
        <div fxFlex>Region Name</div>
        <div fxFlex class="cgi-cell-right">Federal State</div>
    </div>

    <div
        class="cs-table-row action"
        matRipple
        *ngFor="let region of allRegions"
        (click)="openRegionModal(region)"
    >
        <div fxFlex>{{ region.name }}</div>
        <div fxFlex class="cgi-cell-right">
            {{ getName(region.code) }}
        </div>
    </div>
</div>

<div fxLayout="row" fxLayoutAlign="end">
    <button mat-stroked-button color="primary" (click)="openRegionModal(null)">
        Create region
    </button>
</div>
