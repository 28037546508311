<div>
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <h1>Timesheet</h1>
        <button mat-stroked-button color="primary" (click)="openAddDialog()">
            Add entry
        </button>
    </div>
</div>

<mat-card appearance="outlined">
    <mat-tab-group (selectedTabChange)="filterTabChanged($event)">
        <mat-tab label="Basic">
            <div>
                <button mat-icon-button (click)="addYear(-1)">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <mat-form-field>
                    <mat-label>Year</mat-label>
                    <mat-select
                        [(ngModel)]="timesheetEntriesFilter.monthYear.year"
                        id="year"
                        name="year"
                        (selectionChange)="reloadEntries()"
                    >
                        <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button (click)="addYear(1)">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>

                <button mat-icon-button (click)="addMonth(-1)">
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
                <mat-form-field>
                    <mat-label>Month</mat-label>
                    <mat-select
                        [(ngModel)]="timesheetEntriesFilter.monthYear.month"
                        id="month"
                        name="month"
                        (selectionChange)="reloadEntries()"
                    >
                        <mat-option [value]="1">January</mat-option>
                        <mat-option [value]="2">February</mat-option>
                        <mat-option [value]="3">March</mat-option>
                        <mat-option [value]="4">April</mat-option>
                        <mat-option [value]="5">May</mat-option>
                        <mat-option [value]="6">June</mat-option>
                        <mat-option [value]="7">July</mat-option>
                        <mat-option [value]="8">August</mat-option>
                        <mat-option [value]="9">September</mat-option>
                        <mat-option [value]="10">October</mat-option>
                        <mat-option [value]="11">November</mat-option>
                        <mat-option [value]="12">December</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-icon-button (click)="addMonth(+1)">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </mat-tab>

        <mat-tab label="Extended" >
            <div style="padding: 0px 20px 0px 20px" fxLayout="row">
                <mat-form-field style="padding-right: 10px" fxFlex>
                    <mat-label>Start Date</mat-label>
                    <input
                        matInput
                        [max]="timesheetEntriesFilter.end"
                        [matDatepicker]="startPicker"
                        [(ngModel)]="timesheetEntriesFilter.start"
                        name="startDate"
                        id="startDate"
                        required
                        (dateChange)="reloadEntriesExtended()"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="startPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="padding-right: 10px" fxFlex>
                    <mat-label>End Date</mat-label>
                    <input
                        matInput
                        [min]="timesheetEntriesFilter.start"
                        [matDatepicker]="endPicker"
                        [(ngModel)]="timesheetEntriesFilter.end"
                        name="endDate"
                        id="endDate"
                        required
                        (dateChange)="reloadEntriesExtended()"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="endPicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>

                <mat-form-field style="margin-right: 10px" fxFlex>
                    <mat-label>Project</mat-label>
                    <mat-select
                        [multiple]="true"
                        [(ngModel)]="timesheetEntriesFilter.selectedProjects"
                        id="project"
                        name="project"
                        (selectionChange)="projectFilterChanged()"
                    >
                        <mat-option
                            *ngFor="
                                let availableProject of availableProjects
                                    | async
                            "
                            class="left circle"
                            [value]="availableProject.id"
                        >
                            {{ availableProject.name }} ({{
                            availableProject.customerShortName
                            }})
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex>
                    <mat-label>Role</mat-label>
                    <mat-select
                        [multiple]="true"
                        [(ngModel)]="timesheetEntriesFilter.selectedRoles"
                        id="role"
                        name="role"
                        (selectionChange)="reloadEntries()"
                    >
                        <mat-option
                            *ngFor="let availableRole of availableRoles | async"
                            class="left circle"
                            [value]="availableRole.id"
                        >
                            {{ availableRole.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </mat-tab>
    </mat-tab-group>
</mat-card>

<br/>

<div>
    <div
        fxLayout="column"
        class="cs-table"
        *ngFor="let calendarWeek of calendarWeekEntries"
    >
        <mat-expansion-panel
            [expanded]="
                calendarWeek ===
                calendarWeekEntries[calendarWeekEntries.length - 1]
            "
        >
            <mat-expansion-panel-header>
                <div fxFlex>
                    Week {{ calendarWeek.cw }}: {{ cwSum(calendarWeek) }}
                </div>
            </mat-expansion-panel-header>

            <div class="cs-table-row enclosing">
                <div fxFlex="15">Date</div>
                <div fxFlex="70">Project (Role), Task</div>
                <div fxFlex="10"></div>
                <div fxFlex="5">Duration</div>

            </div>

            <ng-container *ngFor="let day of calendarWeek.dayEntries">
                <div
                    class="cs-table-row"
                    matRipple
                    [ngClass]="!isFirst ? 'nodivider' : ''"
                    *ngFor="
                        let item of day.entries;
                        first as isFirst;
                        last as isLast
                    "
                    (click)="openEditDialog(item)"
                >
                    <div fxLayout="row">
                        <div fxFlex="15">
                            <ng-container *ngIf="isFirst">{{
                                item.date.format("DD.MM.YYYY")
                                }}</ng-container>
                        </div>
                        <div fxFlex="60">
                            {{ item.projectName }} -
                            <span style="font-style: italic">{{
                                item.roleName
                                }}</span>
                            <div>
                                {{ item.description }}
                            </div>
                        </div>
                        <div *ngIf="day.entries.length > 1" fxFlex="5">
                            {{ toHours(item.duration) }}
                        </div>
                        <div fxFlex="22" class="cs-cell-right">
                            <button mat-icon-button
                                    (click)="openDuplicateDialog(item, $event)"
                            >
                                <mat-icon>content_copy</mat-icon>
                            </button>
                            <button mat-icon-button
                                    (click)="deleteEntry(item, $event)"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        <div fxFlex="8" class="cs-cell-right">

                            <ng-container *ngIf="isLast">{{
                                sum(day.entries)
                                }}</ng-container>
                            </div>
                    </div>
                </div>
            </ng-container>

            <div class="cs-table-row enclosing">
                <div fxFlex>Calendar week {{ calendarWeek.cw }} sum</div>
                <div fxFlex="0 1 50px" class="cs-cell-right">
                    {{ cwSum(calendarWeek) }}
                </div>
            </div>
        </mat-expansion-panel>
    </div>
</div>

<div *ngIf="calendarWeekEntries.length === 0" style="margin-bottom: 24px">
    <mat-card appearance="outlined">No entries found for the selected period of time.</mat-card>
</div>

<div fxLayout="row" fxLayoutAlign="end">
    <div fxLayout="row" fxLayoutAlign="end center">
        <div *ngIf="calendarWeekEntries.length > 0">
            Total this month: {{ toHours(sumTotal) }}
        </div>
        <div>
            <button
                mat-stroked-button
                color="primary"
                style="margin-left: 10px"
                (click)="openAddDialog()"
            >
                Add entry
            </button>
        </div>
    </div>
</div>


