import { Component, Inject, OnInit } from "@angular/core";
import { Mode } from "../../mode";
import { UserDetails } from "../../users/userDetails";
import { Moment } from "moment";
import { UserService } from "../../users/user.service";
import { VacationService } from "../vacation.service";
import {
    SaveAvailableVacationDaysDTO,
    VacationDaysInformationModel,
} from "../vacation";
import { ArrayMomentUtil } from "../../common";
import { InfoSnackbarUtil } from "../../common/info-snackbar/info-snackbar.component";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
    selector: "app-create-available-vacation-days-dialog",
    templateUrl: "./create-available-vacation-days-dialog.component.html",
    styleUrls: ["./create-available-vacation-days-dialog.component.sass"],
})
export class CreateAvailableVacationDaysDialogComponent implements OnInit {
    Mode = Mode;
    mode: Mode;

    selectedUsers: string[];
    availableUsers: UserDetails[];
    validFrom: Moment;
    validUntil: Moment;
    numberOfVacationDays: number;

    constructor(
        private userService: UserService,
        private vacationService: VacationService,
        @Inject(MAT_DIALOG_DATA)
        private data: CreateAvailableVacationDaysDialogData,
        private dialogRef: MatDialogRef<SaveAvailableVacationDaysDTO[]>,
        private snackBar: MatSnackBar
    ) {
        if (data) {
            this.mode = Mode.EDIT;
            this.selectedUsers = [data.userId];
            this.validFrom =
                data.vacationDaysInformationModel.validFrom.clone();
            this.validUntil =
                data.vacationDaysInformationModel.validUntil.clone();
            this.numberOfVacationDays =
                data.vacationDaysInformationModel.numberOfDays;
        } else {
            this.mode = Mode.CREATE;
            this.selectedUsers = [];
            this.numberOfVacationDays = 0;
        }
    }

    ngOnInit() {
        this.userService
            .readActiveUsers()
            .subscribe((value) => (this.availableUsers = value));
    }

    saveUsersAvailableVacationDays(): void {
        if (this.mode === Mode.CREATE) {
            const availableVacationDays = this.selectedUsers.map(
                (userId) =>
                    new SaveAvailableVacationDaysDTO(
                        this.mode === Mode.EDIT
                            ? this.data.vacationDaysInformationModel.ids
                            : null,
                        userId,
                        ArrayMomentUtil.getArray(this.validFrom),
                        ArrayMomentUtil.getArray(this.validUntil),
                        this.numberOfVacationDays
                    )
            );

            this.vacationService
                .saveUsersAvailableVacationDayInformation(availableVacationDays)
                .subscribe({
                    complete: () => this.dialogRef.close(),
                    error: (err) =>
                        InfoSnackbarUtil.getInfoSnackbarComponentMatSnackBarRef(
                            this.snackBar,
                            err
                        ),
                });
        }

        if (this.mode === Mode.EDIT) {
            const availableVacationDays = new SaveAvailableVacationDaysDTO(
                this.mode === Mode.EDIT
                    ? this.data.vacationDaysInformationModel.ids
                    : null,
                this.data.userId,
                ArrayMomentUtil.getArray(this.validFrom),
                ArrayMomentUtil.getArray(this.validUntil),
                this.numberOfVacationDays
            );

            this.vacationService
                .updateAvailableVacationDays(availableVacationDays)
                .subscribe({
                    complete: () => this.dialogRef.close(),
                    error: (err) =>
                        InfoSnackbarUtil.getInfoSnackbarComponentMatSnackBarRef(
                            this.snackBar,
                            err
                        ),
                });
        }
    }

    addHalfDay(halfDay: number) {
        const numberOfVacationDays = this.numberOfVacationDays + halfDay;

        if (numberOfVacationDays >= 0 && numberOfVacationDays <= 99) {
            this.numberOfVacationDays = numberOfVacationDays;
        }
    }
}

export interface CreateAvailableVacationDaysDialogData {
    userId: string;
    vacationDaysInformationModel: VacationDaysInformationModel;
}
