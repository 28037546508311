import {Component, Inject, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {VacationDayDTO, VacationDayModel, VacationDTO, VacationModel,} from '../vacation';
import {VacationService} from '../vacation.service';
import {UserService} from '../../users/user.service';
import {UserDetails} from '../../users/userDetails';
import * as moment from 'moment';
import {Moment} from 'moment';
import {InfoSnackbarUtil} from '../../common/info-snackbar/info-snackbar.component';
import {CalendarItem, ExcludedDay} from '../../calendar/calendar.component';
import {ArrayMomentUtil} from '../../common';
import {Mode} from '../../mode';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Holiday, HolidayDto, HolidayType} from '../../holidays/holiday';
import {Region} from '../../regions/region';
import {HolidayService} from '../../holidays/holiday.service';

@Component({
    selector: 'app-create-vacation-request-dialog',
    templateUrl: './create-vacation-request-dialog.component.html',
    styleUrls: ['./create-vacation-request-dialog.component.sass'],
})
export class CreateVacationRequestDialogComponent implements OnInit {
    hrRole: boolean;
    availableUsers: UserDetails[];
    selectedUser: string;
    Mode = Mode;
    readonly mode: Mode;

    private leftVacationDays = 0;
    leftVacationDaysDisplay: number;

    calendarItems: CalendarItem[];

    today: Moment;
    min: Moment;
    max: Moment;
    startDayDatepicker: Moment;
    endDayDatepicker: Moment;

    private vacationDayDTOs: VacationDayDTO[];
    numberVacationDays: number;
    vacationDays: VacationDayModel[];
    vacationModelMinDate: Moment;
    vacationModelMaxDate: Moment;
    private vacationModel: VacationModel;

    holidays: Holiday[] = [];

    excludedDays: ExcludedDay[] = [];

    constructor(
        private dialogRef: MatDialogRef<VacationDayDTO[]>,
        private snackBar: MatSnackBar,
        private vacationService: VacationService,
        private holidayService: HolidayService,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: CreateVacationRequestDialogData
    ) {
        this.mode = this.data.mode;
        this.today = moment().startOf('day');
        this.min = this.today.clone().startOf('year');
        this.max = this.today.clone().endOf('year').add(1, 'months');

        this.data.hrRole ? (this.hrRole = true) : (this.hrRole = false);

        if (this.data.vacationModel) {
            this.vacationModel = this.data.vacationModel;
            this.selectedUser = this.vacationModel.userId;
            this.vacationDays = this.vacationModel.vacationDays.map(
                (vacationDayModel) =>
                    new VacationDayModel(
                        vacationDayModel.date,
                        vacationDayModel.daySegment
                    )
            );
            this.vacationModelMinDate = moment
                .min(this.vacationDays.map((value) => value.date))
                .clone();
            this.vacationModelMaxDate = moment
                .max(this.vacationDays.map((value) => value.date))
                .clone();
            this.startDayDatepicker = this.vacationModelMinDate;
            this.endDayDatepicker = this.vacationModelMaxDate;
            this.numberVacationDays =
                this.vacationService.calculateNumberVacationDays(
                    this.vacationDays
                );
        } else {
            this.vacationDays = [];
            this.numberVacationDays = 0;

            if (!this.hrRole) {
                this.userService.me.subscribe((me) => {
                    this.selectedUser = me.principalId;
                });
            }
        }
    }

    ngOnInit() {
        this.vacationDayDTOs = [];
        this.userService
            .readActiveUsers()
            .subscribe(
                (availableUsers) => (this.availableUsers = availableUsers)
            );
        if (this.selectedUser) {
            this.reloadVacations();
            this.reloadLeftVacationDays();
            this.reloadMaxEndDate();
        }
    }

    reloadHolidays(): void {
        if (this.startDayDatepicker === undefined || this.endDayDatepicker === undefined) {
            return;
        }

        this.holidayService.readHolidays(this.startDayDatepicker, this.endDayDatepicker, undefined).subscribe(response => {
            this.holidays = response.map(holidayDto => HolidayDto.toHoliday(holidayDto));
            this.reloadExcludedDays();
        });
    }

    reloadExcludedDays(): void {
        this.excludedDays = this.holidays.map((holiday) => {
            const typeColors = {
                [HolidayType[HolidayType.PUBLIC_HOLIDAY]]: 'green',
                [HolidayType[HolidayType.COMPANY_HOLIDAY]]: 'blue',
            };

            return new ExcludedDay(holiday.date, holiday.name, typeColors[HolidayType[holiday.type]]);
        });
    }

    reloadMaxEndDate() {
        this.vacationService.readAvailableVacationDaysFrom({
            userIds: [this.selectedUser],
            startDay: this.today.date(),
            startMonth: this.today.month() + 1,
            startYear: this.today.year()
        }).subscribe((availableVacationDaysList) => {
            if (availableVacationDaysList.length > 0) {
                const itemWithLargestValidUntil = availableVacationDaysList.reduce((prev, current) => {
                    return moment([prev.validUntil[0], prev.validUntil[1] - 1, prev.validUntil[2]]).isAfter(moment([current.validUntil[0], current.validUntil[1] - 1, current.validUntil[2]])) ? prev : current;
                }, availableVacationDaysList[0]);

                this.max = moment([itemWithLargestValidUntil.validUntil[0], itemWithLargestValidUntil.validUntil[1] - 1, itemWithLargestValidUntil.validUntil[2]]);
            }
        });
    }

    reloadVacations(): void {
        const absenceFilterParams = {
            userIds: [this.selectedUser],
            startDay: this.min.date(),
            startMonth: this.min.month() + 1,
            startYear: this.min.year(),

            endDay: this.max.date(),
            endMonth: this.max.month() + 1,
            endYear: this.max.year(),
        };

        this.vacationService
            .readVacations(absenceFilterParams)
            .subscribe(
                (vacationDTOs) =>
                    (this.calendarItems =
                        this.createCalendarItemsForVacationDTOs(vacationDTOs))
            );
    }

    startOrEndDateChanged(): void {
        this.reloadLeftVacationDays();
        this.reloadHolidays();
    }

    reloadLeftVacationDays() {
        const absenceFilterParams = {
            userIds: [this.selectedUser],
            startDay: this.startDayDatepicker
                ? this.startDayDatepicker.date()
                : this.today.date(),
            startMonth: this.startDayDatepicker
                ? this.startDayDatepicker.month() + 1
                : this.today.month() + 1,
            startYear: this.startDayDatepicker
                ? this.startDayDatepicker.year()
                : this.today.year(),

            endDay: this.endDayDatepicker
                ? this.endDayDatepicker.date()
                : this.max.date(),
            endMonth: this.endDayDatepicker
                ? this.endDayDatepicker.month() + 1
                : this.max.month() + 1,
            endYear: this.endDayDatepicker
                ? this.endDayDatepicker.year()
                : this.max.year(),
        };

        this.vacationService
            .readAvailableVacationDays(absenceFilterParams)
            .subscribe((availableVacationDaysList) => {
                this.leftVacationDays = 0;
                availableVacationDaysList.forEach(
                    (availableVacationDays) =>
                        (this.leftVacationDays =
                            this.leftVacationDays +
                            availableVacationDays.numberOfDays)
                );
                this.leftVacationDaysDisplay = this.leftVacationDays;
            });
    }

    private createCalendarItemsForVacationDTOs(
        vacationDTOs: VacationDTO[]
    ): CalendarItem[] {
        const items: CalendarItem[] = [];

        vacationDTOs
            .map((vacationDTO) => VacationModel.fromVacationDTO(vacationDTO))
            .map((vacation) => {
                vacation.vacationDays.map((vacationDay) =>
                    items.push({
                        moment: vacationDay.date,
                        daySegment: vacationDay.daySegment,
                        clickable:
                            this.mode === Mode.DISPLAY ||
                            this.mode === Mode.CONFIRM
                                ? false
                                : this.vacationDays.find((value) =>
                                value.date.isSame(vacationDay.date)
                            ) !== undefined,
                    })
                );
            });

        return items;
    }

    saveVacationRequest(): void {
        this.vacationDayDTOs = this.vacationDays.map(
            (vacationDay) =>
                new VacationDayDTO(
                    ArrayMomentUtil.getArray(vacationDay.date),
                    vacationDay.daySegment
                )
        );
        if (this.mode === Mode.CREATE) {
            this.vacationService
                .saveVacation(this.selectedUser, this.vacationDayDTOs)
                .subscribe({
                    complete: () => this.dialogRef.close(),
                    error: (err) =>
                        InfoSnackbarUtil.getInfoSnackbarComponentMatSnackBarRef(
                            this.snackBar,
                            err
                        ),
                });
        } else {
            this.vacationService
                .updateVacation(this.vacationModel.id, this.vacationDayDTOs)
                .subscribe({
                    complete: () => this.dialogRef.close(),
                    error: (err) =>
                        InfoSnackbarUtil.getInfoSnackbarComponentMatSnackBarRef(
                            this.snackBar,
                            err
                        ),
                });
        }
    }

    deleteVacationRequest(): void {
        this.vacationService.deleteVacation(this.vacationModel.id).subscribe({
            complete: () => this.dialogRef.close(),
            error: (err) =>
                InfoSnackbarUtil.getInfoSnackbarComponentMatSnackBarRef(
                    this.snackBar,
                    err
                ),
        });
    }

    /**
     * reset:   if CalendarItem is null
     * delete:  if CalendarItem DaySegment is undefined
     * update:  if VacationDay for CalendarItem exists
     * new:     if VacationDay for CalendarItem does not exist
     * */
    handleCalendarItem(calendarItem: CalendarItem): void {
        if (calendarItem === null) {
            this.mode === Mode.CREATE
                ? (this.vacationDays = [])
                : (this.vacationDays = this.vacationModel.vacationDays.map(
                    (vacationDayModel) =>
                        new VacationDayModel(
                            vacationDayModel.date,
                            vacationDayModel.daySegment
                        )
                ));
            this.leftVacationDaysDisplay = this.leftVacationDays;
        } else if (calendarItem.daySegment === undefined) {
            this.vacationDays = this.vacationDays.filter(
                (vacationDay) => !vacationDay.date.isSame(calendarItem.moment)
            );

            this.leftVacationDaysDisplay =
                this.leftVacationDaysDisplay +
                this.numberVacationDays -
                this.vacationService.calculateNumberVacationDays(
                    this.vacationDays
                );
        } else {
            const vacationDayIndex = this.vacationDays.findIndex(
                (vacationDay) => vacationDay.date.isSame(calendarItem.moment)
            );
            vacationDayIndex >= 0
                ? (this.vacationDays[vacationDayIndex].daySegment =
                    calendarItem.daySegment)
                : this.vacationDays.push(
                    new VacationDayModel(
                        calendarItem.moment,
                        calendarItem.daySegment
                    )
                );

            this.leftVacationDaysDisplay =
                this.leftVacationDaysDisplay +
                this.numberVacationDays -
                this.vacationService.calculateNumberVacationDays(
                    this.vacationDays
                );
        }

        this.numberVacationDays =
            this.vacationService.calculateNumberVacationDays(this.vacationDays);
    }
}

export interface CreateVacationRequestDialogData {
    vacationModel?: VacationModel;
    mode: Mode;
    hrRole: boolean;
}
