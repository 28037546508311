<form #userForm="ngForm" (ngSubmit)="hardDeleteUser()">
  <mat-dialog-content>
    <h2>Warning!</h2>
    <label>Delete {{this.model.displayName}} irrevocably?</label>
    <br><label>This action will also affect related data: </label>
    <ul>
      <li>Timesheet entries</li>
      <li>Project entries</li>
      <li>Vacation & Absence entries</li>
    </ul>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button type="button" [mat-dialog-close]="false">Cancel</button>
    <button mat-stroked-button type="submit" style="color: red">Delete</button>
  </mat-dialog-actions>
</form>
