import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
    AvailableVacationDaysDTO,
    SaveAvailableVacationDaysDTO,
    UsersAvailableVacationDaysDTO,
    VacationDayDTO,
    VacationDayModel,
    VacationDTO,
    VacationOverviewDTO,
} from "./vacation";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import {AbsenceFilterParams, AbsenceFilterStartParams} from './absence.service';

@Injectable()
export class VacationService {
    constructor(private http: HttpClient) {}

    saveVacation(
        userId: string,
        vacationDayDTOs: VacationDayDTO[]
    ): Observable<any> {
        return this.http.post(
            `${environment.backendUrl}/api/vacations/user/${userId}`,
            vacationDayDTOs,
            { responseType: "text" }
        );
    }

    readAvailableVacationDays(
        absenceFilterParams: AbsenceFilterParams
    ): Observable<AvailableVacationDaysDTO[]> {
        const params = {
            userId: absenceFilterParams.userIds[0].toString(),

            startDay: absenceFilterParams.startDay.toString(),
            startMonth: absenceFilterParams.startMonth.toString(),
            startYear: absenceFilterParams.startYear.toString(),

            endDay: absenceFilterParams.endDay.toString(),
            endMonth: absenceFilterParams.endMonth.toString(),
            endYear: absenceFilterParams.endYear.toString(),
        };
        return this.http.get<AvailableVacationDaysDTO[]>(
            `${environment.backendUrl}/api/availableVacationDays`,
            { params: params }
        );
    }

    readAvailableVacationDaysFrom(
        absenceFilterParams: AbsenceFilterStartParams
    ): Observable<AvailableVacationDaysDTO[]> {
        const params = {
            userId: absenceFilterParams.userIds[0].toString(),

            startDay: absenceFilterParams.startDay.toString(),
            startMonth: absenceFilterParams.startMonth.toString(),
            startYear: absenceFilterParams.startYear.toString()
        };
        return this.http.get<AvailableVacationDaysDTO[]>(
            `${environment.backendUrl}/api/availableVacationDaysFrom`,
            { params: params }
        );
    }

    readUsersAvailableVacationDayInformation(
        userIds: string[],
        minYear: number
    ): Observable<UsersAvailableVacationDaysDTO[]> {
        const params = {
            userIds: userIds.toString(),
            minYear: minYear.toString(),
        };
        return this.http.get<UsersAvailableVacationDaysDTO[]>(
                `${environment.backendUrl}/api/availableVacationDayInformation`,
            { params: params }
        );
    }

    readVacationOverviewDTOsByUserAndTimerange(
        absenceFilterParams: AbsenceFilterParams
    ): Observable<VacationOverviewDTO[]> {
        const params = {
            userIds: absenceFilterParams.userIds.toString(),

            startDay: absenceFilterParams.startDay.toString(),
            startMonth: absenceFilterParams.startMonth.toString(),
            startYear: absenceFilterParams.startYear.toString(),

            endDay: absenceFilterParams.endDay.toString(),
            endMonth: absenceFilterParams.endMonth.toString(),
            endYear: absenceFilterParams.endYear.toString(),
        };
        return this.http.get<VacationOverviewDTO[]>(
            `${environment.backendUrl}/api/vacationOverview`,
            { params: params }
        );
    }

    readVacationById(vacationId: number): Observable<VacationDTO> {
        return this.http.get<VacationDTO>(
            `${environment.backendUrl}/api/vacations/${vacationId}`
        );
    }

    readVacations(
        absenceFilterParams: AbsenceFilterParams
    ): Observable<VacationDTO[]> {
        const params = {
            userIds: absenceFilterParams.userIds.toString(),
            startDay: absenceFilterParams.startDay.toString(),
            startMonth: absenceFilterParams.startMonth.toString(),
            startYear: absenceFilterParams.startYear.toString(),

            endDay: absenceFilterParams.endDay.toString(),
            endMonth: absenceFilterParams.endMonth.toString(),
            endYear: absenceFilterParams.endYear.toString(),
        };
        return this.http.get<VacationDTO[]>(
            `${environment.backendUrl}/api/vacations`,
            { params: params }
        );
    }

    updateVacation(
        vacationID: number,
        vacationDTOS: VacationDayDTO[]
    ): Observable<any> {
        return this.http.post(
            `${environment.backendUrl}/api/vacations/${vacationID}`,
            vacationDTOS,
            { responseType: "text" }
        );
    }

    verifyVacation(vacationID: number, vacationState: string): Observable<any> {
        return this.http.patch(
            `${environment.backendUrl}/api/vacations/${vacationID}/state/${vacationState}`,
            null,
            { responseType: "text" }
        );
    }

    deleteVacation(vacationID: number): Observable<any> {
        return this.http.delete(
            `${environment.backendUrl}/api/vacations/${vacationID}`,
            { responseType: "text" }
        );
    }

    saveUsersAvailableVacationDayInformation(
        availableVacationDays: SaveAvailableVacationDaysDTO[]
    ): Observable<any> {
        return this.http.post(
            `${environment.backendUrl}/api/availableVacationDays`,
            availableVacationDays,
            { responseType: "text" }
        );
    }

    updateAvailableVacationDays(
        availableVacationDays: SaveAvailableVacationDaysDTO
    ): Observable<any> {
        return this.http.patch(
            `${environment.backendUrl}/api/availableVacationDays`,
            availableVacationDays,
            { responseType: "text" }
        );
    }

    calculateNumberVacationDays(
        vacationDaysModels: VacationDayModel[]
    ): number {
        return vacationDaysModels
            .map((vacationDay) =>
                vacationDay.daySegment === null
                    ? 0
                    : vacationDay.daySegment === "FIRST_HALF" ||
                      vacationDay.daySegment === "SECOND_HALF"
                    ? 0.5
                    : 1
            )
            .reduce((a, b) => a + b, 0);
    }
}
