export const GERMAN_FEDERAL_STATES =
    [
        {'name': 'Baden-Württemberg', 'id': 'bw'},
        {'name': 'Bayern', 'id': 'by'},
        {'name': 'Berlin', 'id': 'be'},
        {'name': 'Brandenburg', 'id': 'bb'},
        {'name': 'Bremen', 'id': 'hb'},
        {'name': 'Hamburg', 'id': 'hh'},
        {'name': 'Hessen', 'id': 'he'},
        {'name': 'Mecklenburg-Vorpommern', 'id': 'mv'},
        {'name': 'Niedersachsen', 'id': 'ni'},
        {'name': 'Nordrhein-Westfalen', 'id': 'nw'},
        {'name': 'Rheinland-Pfalz', 'id': 'rp'},
        {'name': 'Saarland', 'id': 'sl'},
        {'name': 'Sachsen', 'id': 'sn'},
        {'name': 'Sachsen-Anhalt', 'id': 'st'},
        {'name': 'Schleswig-Holstein', 'id': 'sh'},
        {'name': 'Thüringen', 'id': 'th'}
    ];
