import { Component, Inject, OnInit } from "@angular/core";
import { Customer } from "../customer";
import { Mode } from "../../mode";
import { CustomerService } from "../customer.service";
import { UserDetails } from "../../users/userDetails";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-create-customer-dialog",
    templateUrl: "./create-customer-dialog.component.html",
    styleUrls: ["./create-customer-dialog.component.sass"],
})
export class CreateCustomerDialogComponent implements OnInit {
    public Mode = Mode;
    public mode: Mode;
    public model: Customer = new CustomerModel();

    constructor(
        private customerService: CustomerService,
        private dialogRef: MatDialogRef<UserDetails>,
        @Inject(MAT_DIALOG_DATA) public customer: Customer
    ) {
        if (customer) {
            this.model = { ...customer };
            this.mode = Mode.EDIT;
        } else {
            this.model = new CustomerModel();
            this.mode = Mode.CREATE;
        }
    }

    ngOnInit() {}

    saveCustomer() {
        if (this.mode === Mode.CREATE) {
            this.customerService
                .createCustomer({
                    name: this.model.name,
                    shortName: this.model.shortName,
                    enabled: this.model.enabled,
                })
                .subscribe({
                    error: (err) => console.log(err),
                    complete: () => this.dialogRef.close(true),
                });
        } else {
            this.customerService
                .updateCustomer(this.model.id, {
                    name: this.model.name,
                    shortName: this.model.shortName,
                    enabled: this.model.enabled,
                })
                .subscribe({
                    error: (err) => console.log(err),
                    complete: () => this.dialogRef.close(true),
                });
        }
    }
}

export class CustomerModel implements Customer {
    id: number;
    name: string;
    shortName: string;
    enabled: boolean;
}
