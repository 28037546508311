import { Injectable } from "@angular/core";
import {
    Project,
    ProjectCreateRequest,
    ProjectMemberDTO,
    UserProject,
} from "./project";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { ProjectRoleDTO } from "./project-role";
import { Page } from "../common";
import { Moment } from "moment";

@Injectable()
export class ProjectService {
    constructor(private http: HttpClient) {}

    saveProject(project: ProjectCreateRequest): Observable<any> {
        return this.http.post(
            environment.backendUrl + "/api/projects",
            project,
            {
                responseType: "text",
            }
        );
    }

    updateProject(
        projectId: number,
        project: ProjectCreateRequest
    ): Observable<any> {
        return this.http.post(
            environment.backendUrl + "/api/projects/" + projectId,
            project,
            { responseType: "text" }
        );
    }

    deleteProject(projectId: number): Observable<any> {
        return this.http.delete(
            environment.backendUrl + "/api/projects/" + projectId,
            { responseType: "text" }
        );
    }

    readMyProjects(validOnFilter?: Moment): Observable<UserProject[]> {
        let params = new HttpParams();

        if (validOnFilter) {
            params = params.append(
                "validOnYear",
                validOnFilter.year().toString()
            );
            params = params.append(
                "validOnMonth",
                (validOnFilter.month() + 1).toString()
            );
            params = params.append(
                "validOnDay",
                validOnFilter.date().toString()
            );
        }

        return this.http.get<UserProject[]>(
            environment.backendUrl + "/api/my/projects",
            { params: params }
        );
    }

    readMyProjectRolesByProject(
        projectId: number
    ): Observable<ProjectRoleDTO[]> {
        return this.http.get<ProjectRoleDTO[]>(
            environment.backendUrl + `/api/my/projects/${projectId}/roles`
        );
    }

    readAllProjects(
        pg?: Number,
        sz?: Number,
        customerId?: Number,
        states?: string[]
    ): Observable<Page<Project>> {
        let httpParams = new HttpParams();
        if (pg !== undefined) {
            httpParams = httpParams.append("pg", pg.toString());
        }
        if (sz !== undefined) {
            httpParams = httpParams.append("sz", sz.toString());
        }
        if (customerId !== undefined) {
            httpParams = httpParams.append("customerId", customerId.toString());
        }
        if (states !== undefined) {
            httpParams = httpParams.append("state", states.join(","));
        }

        return this.http.get<Page<Project>>(
            environment.backendUrl + "/api/projects",
            { params: httpParams }
        );
    }

    readProject(projectId: number): Observable<Project> {
        return this.http.get<Project>(
            environment.backendUrl + `/api/projects/${projectId}`
        );
    }

    favoriteProject(projectId: number, state: boolean): Observable<any> {
        return this.http.post(
            environment.backendUrl + `/api/projects/${projectId}/favorite`,
            state,
            {
                responseType: "text",
                headers: { "content-type": "application/json" },
            }
        );
    }

    hideProject(projectId: number, state: boolean): Observable<any> {
        return this.http.post(
            environment.backendUrl + `/api/projects/${projectId}/hidden`,
            state,
            {
                responseType: "text",
                headers: { "content-type": "application/json" },
            }
        );
    }

    defaultProject(projectId: number, state: boolean): Observable<any> {
        return this.http.post(
            environment.backendUrl + `/api/projects/${projectId}/default`,
            state,
            {
                responseType: "text",
                headers: { "content-type": "application/json" },
            }
        );
    }

    readProjectMembers(projectId: number): Observable<ProjectMemberDTO[]> {
        return this.http.get<ProjectMemberDTO[]>(
            `${environment.backendUrl}/api/projects/${projectId}/members`
        );
    }

    saveRolesForProjectMember(
        projectId: number,
        userPrincipalId: string,
        roleIds: number[]
    ): Observable<any> {
        return this.http.post(
            `${environment.backendUrl}/api/projects/${projectId}/members/${userPrincipalId}/roles`,
            roleIds,
            {
                responseType: "text",
                headers: { "content-type": "application/json" },
            }
        );
    }

    addProjectMember(
        projectId: number,
        userPrincipalId: string
    ): Observable<any> {
        return this.http.post(
            environment.backendUrl +
                `/api/projects/${projectId}/members/${userPrincipalId}`,
            null,
            {
                responseType: "text",
                headers: { "content-type": "application/json" },
            }
        );
    }

    removeProjectMember(projectId: number, userPrincipalId: string) {
        return this.http.delete(
            environment.backendUrl +
                `/api/projects/${projectId}/members/${userPrincipalId}`,
            {
                responseType: "text",
                headers: { "content-type": "application/json" },
            }
        );
    }
}
