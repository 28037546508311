import { Component, OnInit } from "@angular/core";
import { UserDetails } from "../../users/userDetails";
import {
    UsersAvailableVacationDaysModel,
    UsersAvailableVacationDaysModelUtil,
    VacationDaysInformationModel,
} from "../vacation";
import { VacationService } from "../vacation.service";
import { UserService } from "../../users/user.service";
import * as moment from "moment";
import { Moment } from "moment";
import { MatDialog } from "@angular/material/dialog";
import {
    CreateAvailableVacationDaysDialogComponent,
    CreateAvailableVacationDaysDialogData,
} from "../create-available-vacation-days-dialog/create-available-vacation-days-dialog.component";

@Component({
    selector: "app-available-vacation-day-admin-overview",
    templateUrl: "./available-vacation-day-admin-overview.component.html",
    styleUrls: ["./available-vacation-day-admin-overview.component.sass"],
})
export class AvailableVacationDayAdminOverviewComponent implements OnInit {
    private availableUsers: UserDetails[];
    usersAvailableVacationDays: UsersAvailableVacationDaysModel[];
    private today: Moment;
    UsersAvailableVacationDaysModelUtil = UsersAvailableVacationDaysModelUtil;

    constructor(
        private vacationService: VacationService,
        private userService: UserService,
        private dialog: MatDialog
    ) {
        this.today = moment().startOf("day");
    }

    ngOnInit() {
        this.userService.readActiveUsers().subscribe((availableUsers) => {
            this.availableUsers = availableUsers;
            this.reloadVacationDayInformation();
        });
    }

    private reloadVacationDayInformation(): void {
        this.vacationService
            .readUsersAvailableVacationDayInformation(
                this.availableUsers.map((value) => value.principalId),
                this.today.year()
            )
            .subscribe((usersAvailableVacationDaysDTOs) => {
                this.usersAvailableVacationDays =
                    usersAvailableVacationDaysDTOs.map(
                        (usersAvailableVacationDaysDTO) =>
                            UsersAvailableVacationDaysModel.fromUsersAvailableVacationDaysDTO(
                                usersAvailableVacationDaysDTO
                            )
                    );
            });
    }

    openEditAvailableVacationDaysDialog(
        userId: string,
        vacationDaysInformationModel: VacationDaysInformationModel
    ): void {
        const createAvailableVacationDaysDialogData: CreateAvailableVacationDaysDialogData =
            {
                userId: userId,
                vacationDaysInformationModel: vacationDaysInformationModel,
            };

        this.dialog
            .open(CreateAvailableVacationDaysDialogComponent, {
                data: createAvailableVacationDaysDialogData,
                maxWidth: 500,
                disableClose: true,
            })
            .afterClosed()
            .subscribe(() => {
                this.reloadVacationDayInformation();
            });
    }

    openCreateAvailableVacationDaysDialog(): void {
        this.dialog
            .open(CreateAvailableVacationDaysDialogComponent, {
                data: null,
                maxWidth: 500,
                disableClose: true,
            })
            .afterClosed()
            .subscribe(() => {
                this.reloadVacationDayInformation();
            });
    }

    thereAreVacationDays(): boolean {
        return (
            this.usersAvailableVacationDays.filter(
                (value) => value.vacationDaysInformation.length > 0
            ).length > 0
        );
    }
}
