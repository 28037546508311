import { Component, OnInit } from "@angular/core";
import { ProjectService } from "../project.service";
import { UserProjectModel } from "../project";

@Component({
    selector: "app-project-overview",
    templateUrl: "./project-overview.component.html",
    styleUrls: ["./project-overview.component.sass"],
})
export class MyProjectsOverviewComponent implements OnInit {
    public projects: UserProjectModel[];

    constructor(private projectService: ProjectService) {}

    ngOnInit() {
        this.reloadProjects();
    }

    private reloadProjects() {
        this.projectService
            .readMyProjects()
            .subscribe(
                (projects) =>
                    (this.projects = projects
                        .map((p) => UserProjectModel.fromProject(p))
                        .sort((a, b) =>
                            !a.running + a.customerName + a.name >
                            !b.running + b.customerName + b.name
                                ? 1
                                : -1
                        ))
            );
    }

    toggleFavorite(project: UserProjectModel) {
        this.projectService
            .favoriteProject(project.id, !project.favorite)
            .subscribe({
                next: (_next) => {
                    project.favorite = !project.favorite;
                    if (project.favorite) {
                        project.hidden = false;
                    }
                },
            });
    }

    toggleHidden(project: UserProjectModel) {
        this.projectService.hideProject(project.id, !project.hidden).subscribe({
            next: (_next) => {
                project.hidden = !project.hidden;
                if (project.hidden) {
                    project.favorite = false;
                }
            },
        });
    }

    toggleDefault(project: UserProjectModel) {
        this.projectService
            .defaultProject(project.id, !project.default)
            .subscribe({
                next: (_next) => {
                    this.projects.forEach((p) => {
                        if (p === project) {
                            p.default = !p.default;
                        } else {
                            p.default = false;
                        }
                    });
                },
            });
    }
}
