<form #holidayForm="ngForm" (ngSubmit)="saveHoliday()">
    <h2 mat-dialog-title>
        {{ mode === Mode.CREATE ? "Create" : "Edit" }} Holiday
    </h2>
    <mat-dialog-content>
        <mat-form-field style="width: 80%">
            <mat-label>Holiday-ID</mat-label>
            <input
                matInput
                [(ngModel)]="model.id"
                id="holiday-id"
                name="holiday-id"
                maxlength="64"
                type="text"
                disabled
            />
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <mat-label>Name</mat-label>
            <input
                matInput
                [(ngModel)]="model.name"
                id="name"
                name="name"
                maxlength="64"
                type="text"
                required
            />
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <mat-label>Date</mat-label>
            <input
                matInput
                id="date"
                name="date"
                [matDatepicker]="datePicker"
                [(ngModel)]="datePickerModel"
                (dateChange)="datePickerChanged()"
                required
            />
            <mat-datepicker-toggle
                matSuffix
                [for]="datePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <mat-label>Type</mat-label>
            <mat-select
                id="type"
                name="type"
                [(ngModel)]="model.type"
                required
            >
                <mat-option *ngFor="let type of holidayTypes" [value]="HolidayType[type]">{{
                        HolidayType[type]
                    }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <mat-label>Region</mat-label>
            <mat-select
                id="region"
                name="region"
                [(ngModel)]="model.regionId"
                required
            >
                <mat-option *ngFor="let region of availableRegions" [value]="region.id">{{
                        region.name
                    }}</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button (click)="deleteHoliday()" style="color: red" *ngIf="mode == Mode.EDIT">
          Delete
        </button>
        <button mat-stroked-button type="submit" [disabled]="!holidayForm.valid">
            Save
        </button>
        <button
            type="button"
            mat-stroked-button
            style="margin-left: 10px"
            [mat-dialog-close]="false"
        >
            Cancel
        </button>
    </mat-dialog-actions>
</form>
